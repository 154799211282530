import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle, FaUsers, FaPen, FaDownload } from 'react-icons/fa';

const StepCard = ({ step, index }) => (
  <div 
    className="group relative rounded-xl overflow-hidden
      transition-all duration-300 ease-in-out p-6
      bg-white shadow-lg border border-gray-100
      hover:shadow-xl hover:scale-[1.02]"
  >
    <div className="flex flex-col items-start h-full">
      <div className="flex items-center space-x-4 mb-5">
        <div className="w-12 h-12 rounded-xl flex items-center justify-center bg-gradient-to-br from-rose-100 to-rose-50 shadow-sm">
          {React.cloneElement(step.icon, { className: "w-6 h-6 text-rose-500" })}
        </div>
        <div className="flex flex-col">
          <span className="text-rose-500 font-medium text-sm">Step {index + 1}</span>
          <h3 className="text-xl font-semibold text-gray-800 tracking-tight">
            {step.title}
          </h3>
        </div>
      </div>

      <p className="text-gray-600 text-base mb-5 leading-relaxed">
        {step.shortDesc}
      </p>

      <ul className="space-y-3 text-left w-full mt-auto">
        {step.details.map((detail, idx) => (
          <li key={idx} className="flex items-start text-gray-600 text-sm group/item">
            <span className="w-2 h-2 bg-gradient-to-r from-rose-400 to-rose-500 rounded-full mr-3 mt-1.5 group-hover/item:scale-110 transition-transform duration-200"></span>
            <span className="flex-1">{detail}</span>
          </li>
        ))}
      </ul>
    </div>
    
    <div className="absolute inset-0 bg-gradient-to-br from-rose-500/0 to-rose-500/0 group-hover:from-rose-500/5 group-hover:to-rose-500/0 transition-colors duration-300" />
  </div>
);

const HowItWorks = () => {
  const steps = [
    {
      icon: <FaUserCircle />,
      title: "Personal Details",
      shortDesc: "Create your detailed personal profile with all essential information",
      details: [
        "Basic information (name, date of birth)",
        "Education and occupation details",
        "Religion, caste, and gotra information",
      ]
    },
    {
      icon: <FaUsers />,
      title: "Family Details",
      shortDesc: "Share comprehensive details about your family and background",
      details: [
        "Parents' information",
        "Siblings information",
        "Family type and values",
        "Contact details, etc."
      ]
    },
    {
      icon: <FaPen />,
      title: "Customization",
      shortDesc: "Personalize every aspect of your biodata to match your preferences",
      details: [
        "Choose from multiple templates",
        "Add custom mantra or title",
        "Select preferred language",
        "Customize field labels"
      ]
    },
    {
      icon: <FaDownload />,
      title: "Preview & Download",
      shortDesc: "Review and generate your perfectly formatted biodata",
      details: [
        "Real-time preview",
        "Multiple language support",
        "Professional formatting",
        "Easy download options"
      ]
    }
  ];

  return (
    <div className="bg-gradient-to-b from-rose-50 via-rose-100/30 to-white">
      <div className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="mb-12 text-center">
            <span className="text-rose-500 font-medium text-lg mb-4 block">
              Simple Process
            </span>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4 tracking-tight">
              Create Your Perfect Marriage Biodata
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto text-lg md:text-xl leading-relaxed">
              Follow our simple step-by-step process to create a professional and comprehensive marriage biodata online
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-4">
            {steps.map((step, index) => (
              <StepCard
                key={index}
                step={step}
                index={index}
              />
            ))}
          </div>

          <div className="mt-16 text-center">
            <Link to="/create-biodata">
              <button className="bg-gradient-to-r from-rose-500 to-rose-600 text-white px-6 py-3 rounded-full text-lg font-medium 
                hover:from-rose-600 hover:to-rose-700 transition-all duration-200 shadow-lg hover:shadow-xl
                focus:ring-4 focus:ring-rose-500/20 focus:outline-none">
                Start Creating Your Biodata
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;