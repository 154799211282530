import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { HiMenu, HiX } from 'react-icons/hi';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { label: 'Home', path: '/' },
    { label: 'Templates', path: '/biodata-templates' },
    { label: 'How It Works', path: '/how-it-works' },
    { label: 'FAQs', path: '/faqs' },
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="w-full bg-rose-50 py-4 relative z-50 backdrop-blur-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative">
          <div className={`bg-white/95 backdrop-blur-sm shadow-lg px-4 sm:px-8 py-3 transition-all duration-300 ease-in-out
            ${isMenuOpen ? 'rounded-t-3xl' : 'rounded-full'}`}>
            <div className="flex items-center justify-between">
              {/* Logo - Updated mobile size */}
              <Link to="/" className="flex-shrink-0">
                <img 
                  src="/images/matrimonialbiodatalogo.webp" 
                  alt="Matrimonial Biodata" 
                  className="h-10 sm:h-12 w-auto" 
                />
              </Link>

              {/* Desktop Navigation Menu */}
              <nav className="hidden md:flex flex-grow justify-center ml-20">
                <div className="flex items-center space-x-10">
                  {menuItems.map((item, index) => (
                    <NavLink 
                      key={index} 
                      to={item.path}
                      className={({ isActive }) => 
                        `text-gray-600 hover:text-rose-500 text-m font-medium transition-all duration-200 
                        ${isActive ? 'text-rose-500' : ''}`
                      }
                    >
                      {item.label}
                    </NavLink>
                  ))}
                </div>
              </nav>

              {/* Desktop CTA Button */}
              <Link 
                to="/create-biodata"
                className="hidden md:flex flex-shrink-0 bg-rose-500  text-white px-5 py-2 
                         rounded-full hover:bg-rose-600 hover:text-white transition-all duration-300 items-center gap-2 
                         group ml-8 shadow-sm hover:shadow-md"
              >
                <span className="font-medium  text-sm">Create My Biodata</span>
                <div className="bg-rose-500 group-hover:bg-white rounded-full p-1 transition-colors duration-300">
                  <FaArrowRight className="h-3 w-3 text-white group-hover:text-rose-600 group-hover:translate-x-1 
                                     transition-all duration-300" />
                </div>
              </Link>

              {/* Mobile Menu Button */}
              <button
                className="md:hidden p-2 rounded-lg text-gray-600 hover:text-rose-500 
                         transition-colors duration-200 hover:bg-rose-50"
                onClick={toggleMenu}
              >
                {isMenuOpen ? (
                  <HiX className="h-6 w-6" />
                ) : (
                  <HiMenu className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>

          {/* Mobile Menu */}
          <div className={`md:hidden absolute left-0 right-0 top-full bg-white/95 backdrop-blur-sm shadow-lg rounded-b-3xl
            overflow-hidden transition-all duration-300 ease-in-out origin-top transform -mt-2
            ${isMenuOpen ? 'opacity-100 scale-y-100 border-t border-gray-100' : 'opacity-0 scale-y-0'}`}>
            <nav className="flex flex-col space-y-3 p-6">
              {menuItems.map((item, index) => (
                <NavLink 
                  key={index} 
                  to={item.path}
                  onClick={() => setIsMenuOpen(false)}
                  className={({ isActive }) => 
                    `text-gray-600 hover:text-rose-500 text-sm font-medium py-3 px-4 rounded-lg
                    transition-all duration-200 hover:bg-rose-50/50 ${isActive ? 'text-rose-500 bg-rose-50' : ''}`
                  }
                >
                  {item.label}
                </NavLink>
              ))}
              <Link 
                to="/create-biodata"
                onClick={() => setIsMenuOpen(false)}
                className="flex items-center justify-between text-sm font-medium px-4 py-3 
                         rounded-full border-2 border-rose-500 text-rose-500 hover:bg-rose-500 hover:text-white
                         transition-all duration-300 mt-4"
              >
                <span>Create My Biodata</span>
                <div className="bg-rose-500 rounded-full p-1">
                  <FaArrowRight className="h-3 w-3 text-white" />
                </div>
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;