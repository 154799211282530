const API_CONFIG = {
  development: {
    baseUrl: 'http://localhost:8001',
    timeout: 30000
  },
  production: {
    baseUrl: 'https://mbiodata-server.vercel.app',
    timeout: 30000
  }
};

const currentEnv = process.env.NODE_ENV || 'development';
const { baseUrl: BASE_URL, timeout: TIMEOUT_MS } = API_CONFIG[currentEnv];

const timeoutPromise = (ms) => 
  new Promise((_, reject) => 
    setTimeout(() => reject(new Error('Request timed out')), ms)
  );

export const createCashfreeOrder = async (phoneNumber, name) => {
  try {
    // console.log('Initiating payment request:', {
    //   phoneNumber,
    //   name,
    //   environment: currentEnv,
    //   serverUrl: BASE_URL
    // });

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), TIMEOUT_MS);

    const requestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': window.location.origin
      },
      body: JSON.stringify({
        phoneNumber: phoneNumber.trim(),
        name: name?.trim() || 'Guest User'
      }),
      mode: 'cors',
      signal: controller.signal
    };

    // console.log('Request configuration:', {
    //   url: `${BASE_URL}/api/create-order`,
    //   environment: currentEnv,
    //   ...requestConfig,
    //   body: JSON.parse(requestConfig.body)
    // });

    const response = await fetch(`${BASE_URL}/api/create-order`, requestConfig);
    clearTimeout(timeoutId);

    const responseText = await response.text();
    // console.log('Raw response:', responseText);

    let data;
    try {
      data = responseText ? JSON.parse(responseText) : null;
    } catch (parseError) {
      // console.error('Response parsing error:', {
      //   error: parseError,
      //   responseText,
      //   environment: currentEnv
      // });
      throw new Error(`Invalid response format from ${currentEnv} server`);
    }

    // console.log('Parsed response:', data);

    if (!response.ok) {
      throw new Error(
        data?.message || 
        data?.details || 
        data?.error || 
        `Payment initialization failed on ${currentEnv} server`
      );
    }

    if (!data?.sessionId || !data?.orderId) {
      // console.error('Invalid response structure:', {
      //   data,
      //   environment: currentEnv
      // });
      throw new Error('Missing required payment session data');
    }

    const sessionData = {
      sessionId: data.sessionId,
      orderId: data.orderId
    };

    // console.log('Payment session created successfully:', {
    //   ...sessionData,
    //   environment: currentEnv
    // });
    return sessionData;

  } catch (error) {
    if (error.name === 'AbortError') {
      // console.error(`Request timed out on ${currentEnv} server`);
      throw new Error('Payment request timed out. Please try again.');
    }

    if (error instanceof TypeError) {
      // console.error(`Network or CORS error on ${currentEnv} server:`, error);
      throw new Error(`Network error on ${currentEnv} server. Please check your connection and try again.`);
    }

    // console.error('Payment service error:', {
    //   name: error.name,
    //   message: error.message,
    //   stack: error.stack,
    //   cause: error.cause,
    //   environment: currentEnv
    // });

    throw new Error(
      error.message || `Failed to initialize payment on ${currentEnv} server. Please try again.`
    );
  }
};

export const verifyPayment = async (orderId, paymentId) => {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), TIMEOUT_MS);

    // console.log('Verifying payment:', {
    //   orderId,
    //   paymentId,
    //   environment: currentEnv,
    //   serverUrl: BASE_URL
    // });

    const response = await fetch(`${BASE_URL}/api/payment-status/${orderId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      signal: controller.signal
    });

    clearTimeout(timeoutId);
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || `Payment verification failed on ${currentEnv} server`);
    }

    if (data.data?.orderStatus === "PAID") {
      return {
        status: "SUCCESS",
        details: data.data?.paymentDetails || null
      };
    }

    return {
      status: data.data?.orderStatus || 'PENDING',
      details: data.data?.paymentDetails || null
    };

  } catch (error) {
    // console.error(`Payment verification error on ${currentEnv} server:`, error);
    throw new Error(
      `Unable to verify payment status on ${currentEnv} server. Please check your order status later.`
    );
  }
};

export const formatCurrency = (amount, currency = 'INR') => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: currency
  }).format(amount);
};

export { timeoutPromise };