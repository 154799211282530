import React from 'react';
import {
  getFieldLabel,
  formatHeight,
  formatDate,
  formatTime,
  formatPhone,
  formatSiblings,
  formatValue,
  hasValue,
  shouldShowField,
  processFields
} from '../hooks/templateUtils';
import { getTranslation, monthTranslations } from '../translations';

const Template9 = ({ formData, selectedLanguage, sections, customLabels, isTemplateView = false }) => {
  const t = (key) => getTranslation(selectedLanguage, key);

  const getSectionTitle = (section) => {
    if (section.title && typeof section.title === 'string' && section.title !== t(`sections.${section.id}.title`)) {
      return section.title;
    }
    return t(`sections.${section.id}.title`);
  };

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-[500px] text-gray-300">
      <svg 
        className="w-20 h-20 text-gray-400" 
        fill="none" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        viewBox="0 0 24 24" 
      >
        <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
      <h3 className="mb-2 text-xl font-medium text-gray-300">No Preview Available</h3>
      <p className="text-center text-gray-400 max-w-sm">
        Start filling in your information to see<br />your biodata preview in real-time.
      </p>
    </div>
  );

  const isFormEmpty = () => {
    return Object.keys(formData).length === 0 || 
           Object.values(formData).every(section => 
             Object.keys(section).length === 0 || 
             Object.values(section).every(value => !hasValue(value))
           );
  };

  return (
    <div className="relative rounded-lg shadow-lg p-2 flex flex-col min-h-[800px] w-full" 
    style={{
      background: 'linear-gradient(to bottom right, #FDF8F4, #FFFFFF)',
      borderImage: 'linear-gradient(45deg, #E67E22, #F1C40F) 1',
      borderWidth: '2px',
      borderStyle: 'solid'
    }}>
      {/* Border Decoration */}
      <div className="absolute inset-0">
        <div className="h-full w-full border-[3px] rounded-lg" 
             style={{ 
               borderImage: 'linear-gradient(45deg, #D35400, #F1C40F, #E67E22, #F1C40F) 1',
             }}>
          {/* Corner Designs */}
          <div className="absolute -top-3 -left-3 w-12 h-12" 
               style={{
                 background: 'linear-gradient(135deg, #E67E22, #F1C40F)',
                 clipPath: 'polygon(0 0, 100% 0, 0 100%)',
                 opacity: 0.8
               }} />
          <div className="absolute -top-3 -right-3 w-12 h-12" 
               style={{
                 background: 'linear-gradient(225deg, #E67E22, #F1C40F)',
                 clipPath: 'polygon(100% 0, 100% 100%, 0 0)',
                 opacity: 0.8
               }} />
          <div className="absolute -bottom-3 -left-3 w-12 h-12" 
               style={{
                 background: 'linear-gradient(45deg, #E67E22, #F1C40F)',
                 clipPath: 'polygon(0 0, 100% 100%, 0 100%)',
                 opacity: 0.8
               }} />
          <div className="absolute -bottom-3 -right-3 w-12 h-12" 
               style={{
                 background: 'linear-gradient(315deg, #E67E22, #F1C40F)',
                 clipPath: 'polygon(100% 0, 100% 100%, 0 100%)',
                 opacity: 0.8
               }} />
        </div>
      </div>

      {/* Main Content */}
      <div className="relative w-[85%] max-w-3xl mx-auto styled-scrollbar px-4 flex flex-col flex-grow">

        {isFormEmpty() ? (
          <EmptyState />
        ) : (
          <>
            {/* Mantra with God Images */}
            <div className="flex items-center justify-center gap-4 mt-0 mb-0">
              <img 
                src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
                alt="God Image Left"
                className="w-10 h-10 object-contain" 
                style={{ filter: 'opacity(0.9) sepia(20%) hue-rotate(30deg)' }}
                onError={(e) => {
                  e.target.src = '/images/gods/ganesha1.png';
                  e.target.onerror = null;
                }}
              />
              <div className="text-xl font-medium px-4 mb-4 py-2 rounded-lg text-center"
                   style={{ 
                     color: '#D35400',
                     fontFamily: 'Birthstone',
                     background: 'linear-gradient(to right, transparent, rgba(241, 196, 15, 0.1), transparent)'
                   }}>
                {formData?.customization?.mantra || t('sections.customization.fields.mantra.placeholder')}
              </div>
              <img 
                src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
                alt="God Image Right"
                className="w-10 h-10 object-contain" 
                style={{ filter: 'opacity(0.9) sepia(20%) hue-rotate(30deg)' }}
                onError={(e) => {
                  e.target.src = '/images/gods/ganesha1.png';
                  e.target.onerror = null;
                }}
              />
            </div>

            {/* Profile Section - Centered */}
            <div className="flex flex-col items-center">
              {/* Profile Image, Biodata Title, and Name */}
              <div className="flex items-center gap-4 mb-4">
                {formData?.photoUpload?.profilePhoto && (
                  <div className="relative w-24 h-24 rounded-full overflow-hidden flex-shrink-0">
                    <div className="absolute inset-0 rounded-full border-2" 
                         style={{ borderColor: '#E67E22' }} />
                    <img 
                      src={formData.photoUpload.profilePhoto}
                      alt="Profile"
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.style.display = 'none';
                      }}
                    />
                  </div>
                )}

                <div className="text-center">
                  {/* Biodata Title */}
                  <h1 className="text-xl font-medium relative mb-2" 
                      style={{ color: '#E67E22' }}>
                    {formData?.customization?.biodataTitle || t('sections.customization.fields.biodataTitle.placeholder')}
                  </h1>

                  {/* Name */}
                  {formData?.personalDetails?.name && (
                    <h2 className="text-2xl font-bold" 
                        style={{ color: '#D35400' }}>
                      {formData.personalDetails.name}
                    </h2>
                  )}
                  
                  {/* Date of Birth */}
                  {formData?.personalDetails?.dateOfBirth && (
                    <p className="text-m mt-1" style={{ color: '#666666' }}>
                      {formatDate(formData.personalDetails.dateOfBirth, selectedLanguage, monthTranslations)}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Content Sections - Left Aligned */}
            <div className="space-y-3">
              {sections.map((section) => {
                if (section.id === 'customization') return null;

                const fields = processFields(section, formData, shouldShowField);
                if (fields.length === 0) return null;

                return (
                  <div key={section.id} className="mb-0">
                    <div className="flex items-center mb-2">
                      <div className="w-2 h-2 rounded-full mr-2" 
                           style={{ background: '#E67E22' }} />
                      <h3 className="text-lg mb-4 font-medium" 
                          style={{ color: '#D35400' }}>
                        {getSectionTitle(section)}
                      </h3>
                      <div className="flex-grow h-[1px] ml-4" 
                           style={{ background: 'linear-gradient(to right, #E67E22, transparent)' }} />
                    </div>

                    <div className="space-y-1 pl-1">
  {fields.map(field => {
    const sectionData = formData[section.id] || {};

      // Skip dateOfBirth in Personal Details section since it's shown below name
  if (section.id === 'personalDetails' && field.id === 'dateOfBirth') {
    return null;
  }


                            if (field.isSiblingInfo && section.id === 'familyDetails') {
                              return (
                                <div key="siblings_info" className="flex py-0">
                                  <div className="w-[180px] text-m" style={{ color: '#E67E22' }}>
                                    {t('sections.familyDetails.fields.siblings.label')}
                                  </div>
                                  <div className="w-[70%]" style={{ color: '#666666' }}>
                                    <div className="flex">
                                      <span className="mr-2">:</span>
                                      <div className="flex-1 break-words">
                                        {formatSiblings(sectionData, selectedLanguage, t)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          


    const value = sectionData[field.id];
    let formattedValue = formatValue(value, field.type, field.id, section.id, {
      selectedLanguage,
      monthTranslations,
      t,
      getFieldLabel
    });

    if (field.id === 'education' && sectionData.collegeName) {
      formattedValue = `${formattedValue} (${sectionData.collegeName})`;
    } else if (field.id === 'occupation' && sectionData.organizationName) {
      formattedValue = `${formattedValue} (${sectionData.organizationName})`;
    } else if (field.id === 'fatherOccupation' && sectionData.fatherOrganization) {
      formattedValue = `${formattedValue} (${sectionData.fatherOrganization})`;
    } else if (field.id === 'motherOccupation' && sectionData.motherOrganization) {
      formattedValue = `${formattedValue} (${sectionData.motherOrganization})`;
    }

    if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
      return null;
    }

    return (
      <div key={field.id} className="flex py-0">
        <div className="w-[180px] text-m " style={{ color: '#E67E22' }}>
          {getFieldLabel(section.id, field.id, customLabels, sections, t)}
        </div>
        <div className="flex-1 text-m" style={{ color: '#666666' }}>
          <div className="flex">
            <span className="mr-2">:</span>
            <div className="flex-1 break-words">{formattedValue}</div>
          </div>
        </div>
      </div>
    );
  })}
</div>
                  </div>
                );
              })}
            </div>

            {/* Footer */}
            <div className="mt-auto pt-8">
  <div className="flex items-center justify-center gap-4">
    <div className="h-[2px] w-16" 
         style={{ background: 'linear-gradient(to right, transparent, #E67E22)' }} />
    <div className="w-3 h-3 transform rotate-45" 
         style={{ background: '#F1C40F' }} />
    <div className="h-[2px] w-16" 
         style={{ background: 'linear-gradient(to left, transparent, #E67E22)' }} />
  </div>
</div>
          </>
        )}
      </div>
    </div>
  );
};

Template9.isPremium = true;
export default Template9;