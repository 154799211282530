import React from 'react';
import { FaShieldAlt, FaClock, FaCheckCircle, FaExchangeAlt } from 'react-icons/fa';

const RefundPolicy = () => {
  const policies = [
    {
      icon: <FaClock />,
      title: "24-Hour Window",
      description: "We understand that issues can arise. Submit your refund request within 24 hours of purchase for prompt assistance.",
      points: [
        "Email your request to support@matrimonialbiodata.in",
        "Include your order details and reason for refund",
        "Requests after 24 hours cannot be processed"
      ]
    },
    {
      icon: <FaCheckCircle />,
      title: "Eligibility Criteria",
      description: "Our goal is to resolve any issues you encounter. Here's what you need to know about refund eligibility:",
      points: [
        "Contact our support team with detailed issue description",
        "Provide relevant screenshots if applicable",
        "Allow our team to assist with technical problems",
        "100% refund if we can't resolve your issue"
      ]
    },
    {
      icon: <FaExchangeAlt />,
      title: "Refund Process",
      description: "We've simplified our refund process to ensure a hassle-free experience:",
      points: [
        "Immediate refund initiation upon approval",
        "4-5 business days for amount reflection",
        "Refund via original payment method",
        "Alternative refund options if needed"
      ]
    },
    {
      icon: <FaShieldAlt />,
      title: "Non-Refundable Cases",
      description: "To maintain fairness, refunds are not available in the following situations:",
      points: [
        "User errors or misuse of the product",
        "Violation of terms and conditions",
        "Products accessed beyond refund window",
        "Downloaded or used materials"
      ]
    }
  ];

  return (
    <div className="bg-rose-50 min-h-screen">
      <div className="max-w-5xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Our Refund Policy
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            At Matrimonia Biodata Maker, we value your trust. Our refund policy is designed to be transparent, 
            fair, and hassle-free for all our customers.
          </p>
        </div>

        {/* Policy Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          {policies.map((policy, index) => (
            <div key={index} className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow duration-300">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 rounded-lg bg-rose-100 flex items-center justify-center">
                  {React.cloneElement(policy.icon, { className: "w-6 h-6 text-rose-500" })}
                </div>
                <h2 className="text-xl font-semibold ml-4 text-gray-800">
                  {policy.title}
                </h2>
              </div>
              
              <p className="text-gray-600 mb-4 leading-relaxed">
                {policy.description}
              </p>

              <ul className="space-y-2">
                {policy.points.map((point, idx) => (
                  <li key={idx} className="flex items-start text-gray-600">
                    <span className="w-2 h-2 bg-rose-400 rounded-full mt-2 mr-3 flex-shrink-0"></span>
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Additional Information */}
        <div className="bg-white rounded-xl shadow-sm p-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Policy Updates
          </h2>
          <p className="text-gray-600 leading-relaxed mb-6">
            We reserve the right to modify this refund policy at any time. Changes will be effective 
            immediately upon posting to our website. By using our services, you agree to the terms 
            outlined in this policy.
          </p>

          <div className="bg-rose-50 rounded-lg p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              Need Help?
            </h3>
            <p className="text-gray-600">
              If you have questions about our refund policy or need assistance, please don't hesitate 
              to contact our support team at{' '}
              <a href="mailto:support@matrimonialbiodata.in" className="text-rose-500 hover:text-rose-600">
              support@matrimonialbiodata.in
              </a>
            </p>
          </div>

          <p className="text-sm text-gray-500 text-center mt-8">
            Last updated: September 10, 2023
          </p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;