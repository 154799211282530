import React, { useState, useEffect } from 'react';
import { getTranslation } from './translations';
import { createCashfreeOrder } from '../../services/paymentService';
import { load } from '@cashfreepayments/cashfree-js';
import PropTypes from 'prop-types';

const isProd = process.env.NODE_ENV === 'production';

const BiodataDownloadModal = ({ 
  isOpen, 
  onClose, 
  selectedLanguage,
  selectedTemplate,
  formData,
  sections,
  customLabels
}) => {
  const t = (key) => getTranslation(selectedLanguage, key);

  const [timeLeft, setTimeLeft] = useState(899);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [cashfree, setCashfree] = useState(null);
  const [error, setError] = useState(null);
  const [sdkLoading, setSdkLoading] = useState(true);

  useEffect(() => {
    const initializeCashfree = async () => {
      try {
        setSdkLoading(true);
        
        const cashfreeConfig = {
          mode: isProd ? "production" : "sandbox",
          analyticsMode: isProd ? "production" : "sandbox",
          environment: isProd ? "production" : "sandbox"
        };
        
        // console.log('Initializing Cashfree with config:', cashfreeConfig);
        
        const cashfreeInstance = await load(cashfreeConfig);
        
        if (!cashfreeInstance) {
          throw new Error('Failed to initialize Cashfree SDK');
        }
        
        // console.log('Cashfree SDK initialized successfully');
        setCashfree(cashfreeInstance);
        
      } catch (error) {
        // console.error('Failed to load Cashfree SDK:', error);
        setError('Payment system initialization failed. Please try again.');
      } finally {
        setSdkLoading(false);
      }
    };
  
    if (isOpen && !cashfree) {
      initializeCashfree();
    }
  
    return () => {
      if (!isOpen) {
        setError(null);
        setPhoneNumber('');
        setIsProcessingPayment(false);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
      setTimeLeft(899);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    // console.log('Environment Check:', {
    //   isProd: process.env.NODE_ENV === 'production',
    //   NODE_ENV: process.env.NODE_ENV,
    //   origin: window.location.origin
    // });
  }, []);

  const initializePayment = async () => {
    if (!phoneNumber || phoneNumber.length !== 10) {
      setError(t('modal.phoneInput.error'));
      return;
    }
  
    setIsProcessingPayment(true);
    setError(null);
  
    try {
      const customerName = formData?.personalDetails?.name || "";
      const { sessionId, orderId } = await createCashfreeOrder(phoneNumber, customerName);
      
      if (!sessionId) {
        throw new Error(t('modal.payment.sessionError'));
      }
  
      const biodataData = {
        selectedTemplate,
        formData,
        selectedLanguage,
        sections,
        customLabels
      };
      localStorage.setItem('biodataData', JSON.stringify(biodataData));
  
      if (!cashfree) {
        throw new Error('Payment system not initialized');
      }
  
      // console.log('Initializing checkout with session:', sessionId);
  
      const checkoutConfig = {
        paymentSessionId: sessionId,
        environment: isProd ? "production" : "sandbox",
        returnUrl: `${window.location.origin}/biodata/payment/status?order_id=${orderId}`,
        mode: isProd ? "production" : "sandbox",
        platform: "web",
        paymentMethods: {
          upi: {
            enabled: true
          },
          card: {
            enabled: false
          },
          netbanking: {
            enabled: false
          },
          app: {
            enabled: false
          },
          paylater: {
            enabled: false
          },
          wallet: {
            enabled: false
          }
        },
        theme: {
          colors: {
            primary: "#ea580c",
          },
        },
        style: {
          backgroundColor: "#ffffff",
          color: "#11385b",
          theme: "light",
          showPoweredBy: true,
          errorColor: "#ff0000"
        },
        components: {
          order: {
            visible: true
          },
          card: {
            visible: false
          }
        },
        onSuccess: (data) => {
          window.location.href = `${window.location.origin}/biodata/payment/status?order_id=${orderId}&status=success`;
        },
        onFailure: (data) => {
          window.location.href = `${window.location.origin}/biodata/payment/status?order_id=${orderId}&status=failed`;
        },
        onClose: () => {
          setIsProcessingPayment(false);
        }
      };
  
      // console.log('Checkout config:', checkoutConfig);
  
      try {
        const checkoutResponse = await cashfree.checkout(checkoutConfig);
        // console.log('Checkout response:', checkoutResponse);
      } catch (checkoutError) {
        // console.error('Checkout initialization error:', checkoutError);
        throw new Error('Failed to initialize payment gateway. Please try again.');
      }
  
    } catch (error) {
      // console.error('Payment initialization failed:', error);
      setError(error.message || t('modal.payment.genericError'));
      setIsProcessingPayment(false);
    }
  };
  
  const isPaymentDisabled = 
    sdkLoading || 
    isProcessingPayment || 
    !phoneNumber || 
    phoneNumber.length !== 10 || 
    !cashfree;

  const getButtonText = () => {
    if (sdkLoading) return t('modal.downloadButton.loading');
    if (isProcessingPayment) return t('modal.downloadButton.processing');
    return t('modal.downloadButton.default');
  };

  if (!isOpen) return null;

  return (
    <>
      <div 
        className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm transition-opacity"
        style={{ zIndex: 11000 }}
        onClick={onClose}
      />
      <div 
        className="fixed inset-0 flex items-center justify-center"
        style={{ zIndex: 11001 }}
      >
        <div className="relative bg-white rounded-2xl shadow-xl w-full max-w-2xl mx-4">
          <div className="p-6">
            <button 
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 transition-colors duration-200"
              aria-label={t('modal.close')}
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900 mb-2">
                {t('modal.title')}
              </h2>
              
              <div className="inline-block bg-rose-100 text-rose-600 text-xs font-semibold px-3 py-1 rounded-full mb-4">
                {t('modal.specialOffer')}
              </div>

              <p className="text-gray-600 text-sm mb-5">
                {t('modal.description')} <br/>
                {t('modal.sharingInfo')}
              </p>

              <div className="grid md:grid-cols-2 gap-4 mb-5">
                <div className="flex items-start p-4 bg-rose-50 rounded-xl">
                  <div className="w-8 h-8 bg-rose-100 rounded-lg flex items-center justify-center mr-3 shrink-0">
                    <svg className="w-4 h-4 text-rose-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                    </svg>
                  </div>
                  <div className="text-left">
                    <h3 className="font-medium text-gray-900 text-sm mb-1">
                      {t('modal.features.premium.title')}
                    </h3>
                    <p className="text-gray-600 text-xs">
                      {t('modal.features.premium.description')}
                    </p>
                  </div>
                </div>

                <div className="flex items-start p-4 bg-rose-50 rounded-xl">
                  <div className="w-8 h-8 bg-rose-100 rounded-lg flex items-center justify-center mr-3 shrink-0">
                    <svg className="w-4 h-4 text-rose-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"/>
                    </svg>
                  </div>
                  <div className="text-left">
                    <h3 className="font-medium text-gray-900 text-sm mb-1">
                      {t('modal.features.secure.title')}
                    </h3>
                    <p className="text-gray-600 text-xs">
                      {t('modal.features.secure.description')}
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 rounded-xl p-4 mb-4">
                <div className="text-sm text-gray-600 mb-2">
                  {t('modal.timer.prefix')} 
                  <span className="text-rose-600 font-semibold ml-2">
                    {formatTime(timeLeft)}
                  </span>
                </div>

                <div className="flex items-center justify-center gap-2 mb-3">
                  <span className="text-gray-400 text-base line-through">
                    {t('modal.timer.price.original')}
                  </span>
                  <span className="text-2xl font-bold text-gray-900">
                    {t('modal.timer.price.discounted')}
                  </span>
                  <span className="text-gray-500 text-sm">
                    {t('modal.timer.price.suffix')}
                  </span>
                </div>

                <div className="mb-4">
                  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-center gap-2">
                    <label 
                      htmlFor="phone" 
                      className="text-sm font-medium text-gray-700 whitespace-nowrap"
                    >
                      {t('modal.phoneInput.label')}
                    </label>
                    <div className="relative w-full sm:w-auto">
                      <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                        <span className="text-gray-500">+91</span>
                      </div>
                      <input
                        id="phone"
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                          setPhoneNumber(value);
                          setError(null);
                        }}
                        placeholder={t('modal.phoneInput.placeholder')}
                        className="w-full sm:w-56 pl-12 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent transition-all duration-200"
                        maxLength={10}
                      />
                      {phoneNumber && phoneNumber.length > 0 && phoneNumber.length < 10 && (
                        <div className="absolute right-3 inset-y-0 flex items-center">
                          <span className="text-xs text-rose-500">
                            {10 - phoneNumber.length} digits left
                          </span>
                        </div>
                      )}
                      {phoneNumber && phoneNumber.length === 10 && (
                        <div className="absolute right-3 inset-y-0 flex items-center">
                          <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {error && (
                  <div className="text-rose-600 text-sm mb-3">
                    {error}
                  </div>
                )}

                <button
                  onClick={initializePayment}
                  disabled={isPaymentDisabled}
                  className={`w-full ${
                    isPaymentDisabled ? 'bg-rose-400' : 'bg-rose-600 hover:bg-rose-700'
                  } text-white font-medium py-2.5 px-4 rounded-lg transition-colors duration-200 flex items-center justify} text-white font-medium py-2.5 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center gap-2`}
                >
                  <span>{getButtonText()}</span>
                  {(sdkLoading || isProcessingPayment) ? (
                    <svg className="w-4 h-4 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
                    </svg>
                  ) : (
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                    </svg>
                  )}
                </button>
              </div>

              <div className="flex items-center justify-center gap-1.5 text-xs text-gray-500">
                <svg className="w-4 h-4 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" 
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" 
                    clipRule="evenodd"/>
                </svg>
                <span>{t('modal.trustBadge')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

BiodataDownloadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  customLabels: PropTypes.object
};

BiodataDownloadModal.defaultProps = {
  customLabels: {}
};

export default BiodataDownloadModal;