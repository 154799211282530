// Template1.jsx
import React from 'react';
import {
  getFieldLabel,
  formatHeight,
  formatDate,
  formatTime,
  formatPhone,
  formatSiblings,
  formatValue,
  hasValue,
  shouldShowField,
  processFields
} from '../hooks/templateUtils';
import { getTranslation, monthTranslations } from '../translations';

const Template1 = ({ formData, selectedLanguage, sections, customLabels, isTemplateView = false,  }) => {
  const t = (key) => getTranslation(selectedLanguage, key);

  const getSectionTitle = (section) => {
    // First check if section has a custom title
    if (section.title && typeof section.title === 'string' && section.title !== t(`sections.${section.id}.title`)) {
      return section.title;
    }
    // Fallback to translation
    return t(`sections.${section.id}.title`);
  };
  

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-[500px] text-gray-500">
      <svg 
        className="w-20 h-20 mb-4 text-gray-300" 
        fill="none" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        viewBox="0 0 24 24" 
      >
        <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
      <h3 className="mb-2 text-xl font-medium text-gray-600">No Preview Available</h3>
      <p className="text-center text-gray-500 max-w-sm">
        Start filling in your information to see<br />your biodata preview in real-time.
      </p>
    </div>
  );

  const isFormEmpty = () => {
    return Object.keys(formData).length === 0 || 
           Object.values(formData).every(section => 
             Object.keys(section).length === 0 || 
             Object.values(section).every(value => !hasValue(value))
           );
  };

  return (
    <div className="relative bg-white rounded-lg shadow-lg p-2 flex flex-col min-h-[800px]">
      {/* Decorative Border */}
      <div className="absolute inset-0">
        <div className="h-full w-full border-4 border-rose-300 relative">
          {/* Inner Border */}
          <div className="absolute inset-2 border-2 border-rose-200" />
          {/* Corner Decorations */}
          <div className="absolute -top-1 -left-1 w-8 h-8 border-t-4 border-l-4 border-rose-500" />
          <div className="absolute -top-1 -right-1 w-8 h-8 border-t-4 border-r-4 border-rose-500" />
          <div className="absolute -bottom-1 -left-1 w-8 h-8 border-b-4 border-l-4 border-rose-500" />
          <div className="absolute -bottom-1 -right-1 w-8 h-8 border-b-4 border-r-4 border-rose-500" />
        </div>
      </div>

      {/* Main Content */}
      <div className="relative w-[85%] max-w-3xl mx-auto styled-scrollbar px-4 flex flex-col flex-grow">
        {isFormEmpty() ? (
          <EmptyState />
        ) : (
          <>
{/* Mantra with God Images */}
<div className="flex items-center justify-center gap-4 mt-4 mb-4">
{/* Left God Image */}
<div className="flex items-center justify-center w-10">
  <img 
    src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
    alt="God Image Left"
    className="w-10 h-10 object-contain" 
    onError={(e) => {
      e.target.src = '/images/gods/ganesha1.png';
      e.target.onerror = null;
    }}
  />
</div>
  
  {/* Mantra Text - Using inline-block to maintain vertical alignment */}
  <div className="flex items-center justify-center">
    <div className="text-red-500 font-medium text-xl" style={{ fontFamily: 'Birthstone', lineHeight: '40px' }}>
    {formData?.customization?.mantra || t('sections.customization.fields.mantra.placeholder')}
    </div>
  </div>
  
  {/* Right God Image */}
<div className="flex items-center justify-center w-10">
  <img 
    src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
    alt="God Image Right"
    className="w-10 h-10 object-contain" 
    onError={(e) => {
      e.target.src = '/images/gods/ganesha1.png';
      e.target.onerror = null;
    }}
  />
</div>
</div>

            {/* Biodata Title */}
            <h1 className="text-xl font-bold text-red-600 mb-0 text-center">
            {formData?.customization?.biodataTitle || t('sections.customization.fields.biodataTitle.placeholder')}
            </h1>
              
            {/* Profile Name */}
            {formData?.personalDetails?.name && (
              <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                {formData.personalDetails.name}
              </h2>
            )}

            {/* Content Sections */}
            <div className="space-y-4">
              {sections.map((section) => {
                if (section.id === 'customization') return null;

                const fields = processFields(section, formData, shouldShowField);
                if (fields.length === 0) return null;

                return (
                  <div key={section.id} className="mb-3">
                    {section.id === 'personalDetails' ? (
                      <>
                        <h3 className="text-red-500 text-base font-medium text-left mb-2">
                        ※ {getSectionTitle(section)} ※
                        </h3>
                        <div className="flex justify-between items-start gap-1">
                          <div className="flex-1 min-w-0">
                            <div className="space-y-1 text-left text-m">
                              {fields.map(field => {
                                const sectionData = formData[section.id] || {};
                                const value = sectionData[field.id];
                                let formattedValue = formatValue(value, field.type, field.id, section.id, {
                                  selectedLanguage,
                                  monthTranslations,
                                  t,
                                  getFieldLabel
                                });
                                
                                if (field.id === 'education' && sectionData.collegeName) {
                                  formattedValue = `${formattedValue} (${sectionData.collegeName})`;
                                } else if (field.id === 'occupation' && sectionData.organizationName) {  // Add this condition
                                  formattedValue = `${formattedValue} (${sectionData.organizationName})`;
                                }
                                
                                if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
                                  return null;
                                }

                                return (
                                  <div key={field.id} className="flex py-0"> 
<div className={`${formData?.photoUpload?.profilePhoto ? 'w-[200px]' : 'w-[180px]'} text-gray-600`}>
    {getFieldLabel(section.id, field.id, customLabels, sections, t)}
  </div>
  <div className="w-[70%] text-gray-800">
    <div className="flex">
      <span className="mr-2">:</span>
      <div className="flex-1 break-words">{formattedValue}</div>
    </div>
  </div>
</div>
                                );
                              })}
                            </div>
                          </div>
                          {formData?.photoUpload?.profilePhoto && (
                            <div className="w-36 h-auto flex-shrink-0 rounded-lg border border-rose-200 shadow-md overflow-hidden">
                              <img 
                                src={formData.photoUpload.profilePhoto}
                                alt="Profile"
                                className="w-full h-full object-cover"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.style.display = 'none';
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <h3 className="text-red-500 text-base font-medium text-left mb-2">
                        ※ {getSectionTitle(section)} ※
                        </h3>
                        <div className="space-y-1 text-left text-m">
                        {fields.map(field => {
  const sectionData = formData[section.id] || {};
  if (field.isSiblingInfo && section.id === 'familyDetails') {
    return (
      <div key="siblings_info" className="flex py-0">
        <div className="w-[180px] text-gray-600">
          {t('sections.familyDetails.fields.siblings.label')}
        </div>
        <div className="w-[70%] text-gray-800">
          <div className="flex">
            <span className="mr-2">:</span>
            <div className="flex-1 break-words">
              {formatSiblings(sectionData, selectedLanguage, t)} {/* Pass t directly */}
            </div>
          </div>
        </div>
      </div>
    );
  }

                            const value = sectionData[field.id];
let formattedValue = formatValue(value, field.type, field.id, section.id, {
  selectedLanguage,
  monthTranslations,
  t,
  getFieldLabel
});

if (field.id === 'fatherOccupation' && sectionData.fatherOrganization) {
  formattedValue = `${formattedValue} (${sectionData.fatherOrganization})`;
} else if (field.id === 'motherOccupation' && sectionData.motherOrganization) {
  formattedValue = `${formattedValue} (${sectionData.motherOrganization})`;
} else if (field.id === 'education' && sectionData.collegeName) {
  formattedValue = `${formattedValue} (${sectionData.collegeName})`;
} else if (field.id === 'occupation' && sectionData.organizationName) {
  formattedValue = `${formattedValue} (${sectionData.organizationName})`;
}

if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
  return null;
}

                            return (
                              <div key={field.id} className="flex py-0">
                                <div className="w-[180px] text-gray-600">
                                  {getFieldLabel(section.id, field.id, customLabels, sections, t)}
                                </div>
                                <div className="w-[70%] text-gray-800">
                                  <div className="flex">
                                    <span className="mr-2">:</span>
                                    <div className="flex-1 break-words">{formattedValue}</div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>

                   {/* Footer */}
<div className="mt-auto mb-1 text-center">
  <div className="flex items-center justify-center w-full my-1 opacity-80">
    <div className="flex-grow h-[1px] max-w-[100px] bg-gradient-to-r from-transparent via-red-300 to-transparent"></div>
    <div className="mx-4 text-red-500">
      <span className="text-lg">✤</span>
    </div>
    <div className="flex-grow h-[1px] max-w-[100px] bg-gradient-to-r from-transparent via-red-300 to-transparent"></div>
  </div>
</div>
          </>
        )}
      </div>
    </div>
  );
};
Template1.isPremium = true;
export default Template1;