// src/components/BioDataMaker/TemplateSelector.jsx
import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { getTranslation } from './translations';
import { useBiodataStorage } from './hooks/useBiodataStorage';
import { templates } from './templates';
import BiodataDownloadModal from './BiodataDownloadModal';

// A4 dimensions
const A4_WIDTH_MM = 210;
const A4_HEIGHT_MM = 297;

const TemplateSelector = ({ 
  isOpen, 
  onClose, 
  onSelect,
  formData = {}, 
  selectedLanguage, 
  sections, 
  customLabels = {},
  selectedTemplate = 'default',
  onDownload
}) => {
  const t = (key) => getTranslation(selectedLanguage, key);
  const { storedBiodata } = useBiodataStorage();
  const [previewTemplate, setPreviewTemplate] = useState(selectedTemplate);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState('preview');
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const previewRef = React.useRef(null);
  const templateRef = React.useRef(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen, storedBiodata]);

  useEffect(() => {
    if (isOpen) {
      setPreviewTemplate(selectedTemplate);
      updateContainerWidth();
      checkMobile();
    }
  }, [isOpen, selectedTemplate]);

  useEffect(() => {
    const handleResize = () => {
      updateContainerWidth();
      checkMobile();
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const checkMobile = () => {
    setIsMobile(window.innerWidth < 768);
  };

  const updateContainerWidth = () => {
    if (previewRef.current) {
      setContainerWidth(previewRef.current.offsetWidth);
    }
  };

  const handleTemplateSelect = (templateId) => {
    setPreviewTemplate(templateId);
    if (typeof onSelect === 'function') {
      onSelect(templateId);
    }
    if (isMobile) {
      setActiveTab('preview');
    }
  };

  const generateFiles = async () => {
    setIsGeneratingPdf(true);
    try {
      await document.fonts.ready;
      
      const templateEl = templateRef.current.querySelector('.template-content');
      if (!templateEl) {
        throw new Error('Template element not found');
      }

      const tempContainer = document.createElement('div');
      tempContainer.style.cssText = `
        width: 210mm;
        height: 297mm;
        position: absolute;
        left: -9999px;
        top: -9999px;
        background: white;
      `;
      document.body.appendChild(tempContainer);

      const clone = templateEl.cloneNode(true);
      clone.style.cssText = `
        width: 210mm;
        height: 297mm;
        transform: none;
        position: relative;
        background: white;
        padding: 2mm;
        margin: 0;
      `;
      tempContainer.appendChild(clone);

      const textElements = clone.querySelectorAll('*');
      textElements.forEach(el => {
        if (el.nodeType === 1) {
          const computed = window.getComputedStyle(el);
          el.style.lineHeight = computed.lineHeight;
          el.style.transform = 'none';
          el.style.position = computed.position;
          el.style.margin = computed.margin;
          el.style.padding = computed.padding;
        }
      });

      let fileName = 'biodata';
      const name = formData?.personalDetails?.name || '';
      if (name && typeof name === 'string' && name.trim()) {
        const cleanName = name.trim()
          .toLowerCase()
          .replace(/[^a-z0-9\s]/g, '')
          .replace(/\s+/g, '_');
        fileName = `biodata_${cleanName}`;
      }

      void tempContainer.offsetHeight;

      await new Promise(resolve => setTimeout(resolve, 100));

      const canvas = await html2canvas(clone, {
        scale: 4,
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: '#ffffff',
        width: clone.offsetWidth,
        height: clone.offsetHeight,
        windowWidth: clone.offsetWidth,
        windowHeight: clone.offsetHeight,
        onclone: (clonedDoc) => {
          const styles = clonedDoc.createElement('style');
          styles.textContent = `
            * {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              text-rendering: optimizeLegibility;
              line-height: normal !important;
            }
          `;
          clonedDoc.head.appendChild(styles);
        }
      });

      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        compress: true,
        precision: 16
      });

      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      pdf.addImage(imgData, 'JPEG', 0, 0, A4_WIDTH_MM, A4_HEIGHT_MM, undefined, 'FAST');
      
      const pdfFileName = `${fileName}.pdf`;
      pdf.save(pdfFileName);

      await new Promise(resolve => setTimeout(resolve, 500));

      const pngFileName = `${fileName}.png`;
      const link = document.createElement('a');
      link.download = pngFileName;
      link.href = canvas.toDataURL('image/png', 1.0);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      document.body.removeChild(tempContainer);
      return true;

    } catch (error) {
      console.error('Error generating files:', error);
      throw error;
    } finally {
      setIsGeneratingPdf(false);
    }
  };

  const handleContinueAndDownload = async () => {
    if (typeof onSelect === 'function') {
      onSelect(previewTemplate);
    }
    
    const CurrentTemplate = templates[previewTemplate];
    
    if (CurrentTemplate.isPremium) {
      setShowDownloadModal(true);
    } else {
      try {
        setIsGeneratingPdf(true);
        await generateFiles();
      } catch (error) {
        console.error('Error generating files:', error);
      } finally {
        setIsGeneratingPdf(false);
      }
    }
  };

  if (!isOpen) return null;

  const getMainPreviewScale = () => {
    if (!containerWidth) return 0;
    const maxScale = isMobile ? 0.4 : 0.8;
    const scale = Math.min(containerWidth / 793.7007874, maxScale);
    return scale;
  };

  const transformFormData = (data) => {
    if (!data) return {};
    const transformed = {};
    for (const sectionKey in data) {
      transformed[sectionKey] = {};
      for (const fieldKey in data[sectionKey]) {
        const value = data[sectionKey][fieldKey];
        if (value && typeof value === 'object') {
          if ('value' in value) {
            transformed[sectionKey][fieldKey] = value.value;
          } else if ('type' in value && value.type === 'image') {
            transformed[sectionKey][fieldKey] = value.value;
          } else {
            transformed[sectionKey][fieldKey] = value;
          }
        } else {
          transformed[sectionKey][fieldKey] = value;
        }
      }
    }
    return transformed;
  };

  const PreviewContainer = ({ children, isMainPreview = false, className = '', templateId = '' }) => {
    const containerWidth = isMainPreview ? `${793.7007874 * getMainPreviewScale()}px` : '320px';
    const containerHeight = isMainPreview ? `${1209.4488189 * getMainPreviewScale()}px` : '485px';
    
    const getContainerScale = () => {
      const baseWidth = 793.7007874;
      const containerWidthValue = isMainPreview ? 
        793.7007874 * getMainPreviewScale() : 
        320;
      return containerWidthValue / baseWidth;
    };

    const isPremium = templates[templateId]?.isPremium;

    return (
      <div 
        className={`preview-container ${className}`}
        style={{
          width: containerWidth,
          height: containerHeight,
          margin: '0 auto',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div 
          className="template-content"
          style={{
            transform: `scale(${getContainerScale()})`,
            transformOrigin: 'center center',
            width: '210mm',
            height: '320mm',
            backgroundColor: 'white',
            position: 'absolute',
            padding: '2mm',
            boxSizing: 'border-box'
          }}
        >
          {children}
        </div>

        {isPremium && isMainPreview && (
          <div 
            className="absolute inset-0 pointer-events-none select-none"
            style={{
              background: 'linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.1) 100%)',
              zIndex: 10
            }}
          >
            <div className="absolute inset-0 flex items-center justify-center">
              <div 
                className="transform -rotate-45 text-gray-400/30 font-bold whitespace-nowrap"
                style={{
                  fontSize: isMobile ? '2rem' : '4rem'
                }}
              >
                MatrimonialBiodata.in
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const transformedFormData = transformFormData(formData);

  return (
    <>
      <div 
        className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 transition-opacity duration-300"
        style={{ 
          position: 'fixed',
          zIndex: 9999,
          minWidth: '100vw',
          minHeight: '100vh',
          margin: 0,
          padding: 0
        }}
        onClick={onClose}
      />

      <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center overflow-hidden"
        style={{ 
          position: 'fixed',
          zIndex: 10000,
          minWidth: '100vw',
          minHeight: '100vh',
          margin: 0,
          padding: 0
        }}
      >
        <div className="w-full h-full lg:h-[90vh] bg-white rounded-xl shadow-xl flex flex-col max-w-7xl lg:my-4 lg:mx-4 overflow-hidden">
          {/* Header */}
          <div className="shrink-0 px-4 sm:px-6 py-4 border-b border-gray-200 flex items-center justify-between bg-white rounded-t-xl">
            <div>
              <h2 className="text-lg sm:text-xl font-semibold text-gray-900">{t('chooseTemplate')}</h2>
              <p className="text-xs sm:text-sm text-gray-500">{t('selectTemplateDesc')}</p>
            </div>
            <button
              onClick={onClose}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
            >
              <svg
                className="w-5 h-5 sm:w-6 sm:h-6 text-gray-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Mobile Tabs */}
          {isMobile && (
            <div className="relative flex p-0.5 space-x-0.5 bg-gray-100/80 mx-6 my-2 rounded-lg">
              <div
                className="absolute inset-0.5 transition-all duration-200 ease-out"
                style={{
                  transform: `translateX(${activeTab === 'preview' ? '0%' : '100%'})`,
                  width: '50%',
                }}
              >
                <div className="w-full h-full bg-white rounded-md shadow-sm" />
              </div>

              <button
                className={`relative flex-1 flex items-center justify-center py-2 px-2 text-sm font-medium rounded-md transition-colors duration-200 ${
                  activeTab === 'preview'
                    ? 'text-rose-600'
                    : 'text-gray-600 hover:text-gray-800'
                }`}
                onClick={() => setActiveTab('preview')}
              >
                <svg
                  className={`w-3.5 h-3.5 mr-1.5 ${
                    activeTab === 'preview' ? 'text-rose-600' : 'text-gray-400'
                  }`}
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                {t('preview')}
              </button>

              <button
                className={`relative flex-1 flex items-center justify-center py-2 px-2 text-sm font-medium rounded-md transition-colors duration-200 ${
                  activeTab ==='templates'
                    ? 'text-rose-600'
                    : 'text-gray-600 hover:text-gray-800'
                }`}
                onClick={() => setActiveTab('templates')}
              >
                <svg
                  className={`w-3.5 h-3.5 mr-1.5 ${
                    activeTab === 'templates' ? 'text-rose-600' : 'text-gray-400'
                  }`}
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                </svg>
                {t('template')}
              </button>
            </div>
          )}

          {/* Content Container */}
          <div className="flex-1 flex flex-col lg:flex-row overflow-hidden">
            {/* Preview Section */}
            <div className={`w-full lg:w-[65%] lg:border-r border-gray-200 bg-gray-50 overflow-y-auto flex flex-col ${isMobile && activeTab !== 'preview' ? 'hidden' : ''}`}>
            <div className="p-2 sm:p-4 lg:p-6 w-full flex-1 pb-20 lg:pb-6" ref={previewRef}>
                {isLoading ? (
                  <div className="w-full h-full min-h-[80vh] flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <svg 
                        className="animate-spin h-8 w-8 text-rose-500" 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24"
                      >
                        <circle 
                          className="opacity-25" 
                          cx="12" 
                          cy="12" 
                          r="10" 
                          stroke="currentColor" 
                          strokeWidth="4"
                        />
                        <path 
                          className="opacity-75" 
                          fill="currentColor" 
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </div>
                  </div>
                ) : storedBiodata ? (
                  <div className="w-full p-8" ref={templateRef}>
                    <PreviewContainer isMainPreview={true} templateId={previewTemplate}>
                      {React.createElement(templates[previewTemplate], {
                        formData: transformedFormData,
                        selectedLanguage,
                        sections,
                        customLabels,
                        isPreview: true
                      })}
                    </PreviewContainer>
                  </div>
                ) : (
                  <div className="h-full min-h-[80vh] flex items-center justify-center p-8">
                    <div className="text-center">
                      <div className="mb-4">
                        <svg 
                          className="mx-auto h-24 w-24 text-gray-400" 
                          fill="none" 
                          stroke="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V7a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
                          />
                        </svg>
                      </div>
                      <h3 className="text-2xl font-medium text-gray-600 mb-2">
                        {t('fillBiodataFirst')}
                      </h3>
                      <p className="text-l text-gray-400 max-w-sm mx-auto">
                        {t('fillBiodataDescription') || 'Please fill in your biodata details first to preview and download templates.'}
                      </p>
                    </div>
                  </div>
                )}
              </div>

{/* Mobile Download Button */}
{isMobile && (
  <div className="fixed bottom-0 left-0 right-0 px-4 py-3 border-t border-gray-200 bg-white">
    <button
      onClick={handleContinueAndDownload}
      disabled={isGeneratingPdf}
      className={`w-full ${
        isGeneratingPdf ? 'bg-rose-400' : 'bg-rose-500 hover:bg-rose-600'
      } text-white px-4 py-3 rounded-lg text-base font-medium
        transition-colors duration-200 flex items-center justify-center gap-2`}
    >
      <span>
        {isGeneratingPdf 
          ? t('generating') 
          : templates[previewTemplate].isPremium 
            ? t('continueAndDownload')
            : t('downloadNow')}
      </span>
      <svg 
        className={`w-4 h-4 ${isGeneratingPdf ? 'animate-spin' : ''}`}
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
      >
        {isGeneratingPdf ? (
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
          />
        ) : (
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        )}
      </svg>
    </button>
  </div>
)}
            </div>

            {/* Templates Grid */}
            <div className={`w-full lg:w-[35%] flex flex-col ${isMobile && activeTab !== 'templates' ? 'hidden' : ''}`}>
              <div className="flex-1 overflow-y-auto max-h-[100vh]">
                <div className="p-2 sm:p-4 lg:p-6">
                  <div className="w-[90%] md:w-[85%] py-6 mx-auto">
                    <div className="grid grid-cols-1 gap-2 md:gap-4">
                      {Object.entries(templates).map(([templateId, Template]) => (
                        <div 
                          key={templateId}
                          onClick={() => handleTemplateSelect(templateId)}
                          className={`relative cursor-pointer group rounded-lg border-2 overflow-hidden w-[320px] sm:w-full mx-auto ${
                            previewTemplate === templateId 
                              ? 'border-rose-500 bg-rose-50' 
                              : 'border-gray-200 hover:border-rose-400'
                          }`}
                        >
                          {/* Premium/Free Label */}
                          <div className="absolute top-2 right-2 z-10">
                            <div className={`
                              px-3 py-1 rounded-full text-sm font-medium
                              ${Template.isPremium 
                                ? 'bg-rose-500 text-white shadow-md' 
                                : 'bg-rose-500 text-white'}
                            `}>
                              {Template.isPremium ? 'Premium' : 'Free'}
                            </div>
                          </div>

                          <PreviewContainer templateId={templateId}>
                            <Template
                              formData={transformedFormData}
                              selectedLanguage={selectedLanguage}
                              sections={sections}
                              customLabels={customLabels}
                              isPreview={true}
                              isTemplateView={true}
                            />
                          </PreviewContainer>

                          <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-200">
                            <div className="absolute inset-0 flex items-center justify-center">
                              <span 
                                className={`
                                  px-4 py-2 rounded-full text-sm font-medium
                                  ${previewTemplate === templateId 
                                    ? 'bg-rose-600 text-white'
                                    : 'bg-rose-500 text-white opacity-0 group-hover:opacity-100'
                                  }
                                  transition-all duration-200
                                `}
                              >
                                {previewTemplate === templateId ? t('selected') : t('useTemplate')}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Desktop Download Button */}
              {!isMobile && (
                <div className="shrink-0 p-2 sm:p-4 lg:p-6 border-t border-gray-200 bg-white">
                  <button
                    onClick={handleContinueAndDownload}
                    disabled={isGeneratingPdf}
                    className={`w-full ${
                      isGeneratingPdf ? 'bg-rose-400' : 'bg-rose-500 hover:bg-rose-600'
                    } text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-m font-medium
                      transition-colors duration-200 flex items-center justify-center gap-2`}
                  >
                    <span>
                      {isGeneratingPdf 
                        ? t('generating') 
                        : templates[previewTemplate].isPremium 
                          ? t('continueAndDownload')
                          : t('downloadNow')}
                    </span>
                    <svg 
                      className={`w-4 h-4 ${isGeneratingPdf ? 'animate-spin' : ''}`}
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      {isGeneratingPdf ? (
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
                        />
                      ) : (
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                        />
                      )}
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BiodataDownloadModal 
        isOpen={showDownloadModal}
        onClose={() => setShowDownloadModal(false)}
        onDownload={generateFiles}
        selectedLanguage={selectedLanguage}
        selectedTemplate={selectedTemplate}
        formData={formData}
        sections={sections}
        customLabels={customLabels}
      />
    </>
  );
};

export default TemplateSelector;