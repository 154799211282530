import React from 'react';
import { Link } from 'react-router-dom';
import { FaHeart, FaStar } from 'react-icons/fa';

const AboutUs = () => {
 return (
   <div className="bg-rose-50 min-h-screen">
     <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
       {/* Hero Section */}
       <div className="text-center mb-16">
         <span className="text-rose-500 font-medium">Who We Are</span>
         <h1 className="text-4xl font-bold text-gray-900 mt-2 mb-4">
           Building Bridges to Beautiful Beginnings
         </h1>
         <p className="text-xl text-gray-600">
           We're transforming the way marriage biodatas are created, one story at a time.
         </p>
       </div>

       {/* Main Content */}
       <div className="bg-white rounded-xl shadow-sm p-8 space-y-8">
         {/* Story Section */}
         <div className="prose max-w-none">
           <p className="text-gray-600 text-lg leading-relaxed">
             Sometimes the simplest ideas come from everyday challenges. For us, it was watching 
             friends and family members spend hours trying to create the perfect marriage biodata. 
             The process was tedious, the results were inconsistent, and the stress was unnecessary.
           </p>

           <p className="text-gray-600 text-lg leading-relaxed mt-6">
             That's when we asked ourselves: "Why isn't there a better way?" This simple question 
             led to the birth of MatrimonialBiodata - a platform that turns hours of formatting 
             and editing into minutes of simple, guided steps.
           </p>

           {/* Core Values */}
           <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
             <div className="bg-rose-50/50 rounded-lg p-6">
               <h3 className="text-lg font-semibold text-gray-800 mb-3 flex items-center">
                 <FaStar className="text-rose-500 w-5 h-5 mr-2" />
                 Simplicity First
               </h3>
               <p className="text-gray-600">
                 We believe that creating a biodata shouldn't require technical expertise. 
                 Our tool makes it as easy as telling your story.
               </p>
             </div>

             <div className="bg-rose-50/50 rounded-lg p-6">
               <h3 className="text-lg font-semibold text-gray-800 mb-3 flex items-center">
                 <FaStar className="text-rose-500 w-5 h-5 mr-2" />
                 Personal Touch
               </h3>
               <p className="text-gray-600">
                 Every biodata is unique because every story is unique. Our templates are 
                 designed to highlight your individuality.
               </p>
             </div>
           </div>

           {/* Impact Section */}
           <div className="mt-8">
             <h3 className="text-xl font-semibold text-gray-800 mb-4">
               Making a Difference
             </h3>
             <p className="text-gray-600 text-lg leading-relaxed">
               Today, thousands of people use our platform to create professional marriage 
               biodatas that truly represent who they are. What started as a solution to a 
               common problem has grown into a trusted companion in people's journey to 
               finding their life partner.
             </p>
           </div>
         </div>

         {/* Our Promise */}
         <div className="bg-gradient-to-br from-rose-50 to-rose-100/50 rounded-lg p-6 mt-8">
           <div className="flex items-center justify-center mb-4">
             <FaHeart className="text-rose-500 w-6 h-6" />
           </div>
           <p className="text-gray-700 text-center font-medium">
             Behind every biodata is a hope, a dream, and a future waiting to unfold. 
             We're honored to be a small part of your big journey.
           </p>
         </div>

         {/* Team Quote */}
         <div className="text-center mt-8">
           <p className="text-gray-500 italic">
             "We believe that when technology meets tradition with respect and understanding, 
             beautiful things happen."
           </p>
           <p className="text-gray-600 mt-2">- The MatrimonialBiodata Team</p>
         </div>

         {/* Call to Action */}
         <div className="text-center mt-12">
           <p className="text-gray-600 mb-6">
             Begin your journey with us today
           </p>
           <Link 
             to="/create-biodata" 
             className="inline-block bg-gradient-to-r from-rose-500 to-rose-600 
               text-white px-8 py-3 rounded-lg font-medium hover:from-rose-600 
               hover:to-rose-700 transition-all duration-200 shadow-lg 
               hover:shadow-xl focus:ring-4 focus:ring-rose-500/20 
               focus:outline-none"
           >
             Create Your Biodata
           </Link>
         </div>
       </div>
     </div>
   </div>
 );
};

export default AboutUs;
