// src/components/BioDataMaker/hooks/templateUtils.js

export const getFieldLabel = (sectionId, fieldId, customLabels, sections, t) => {
      if (!sectionId || !fieldId) return '';
      
      const customLabel = customLabels?.[`${sectionId}.${fieldId}`];
      if (fieldId.startsWith('custom_')) {
        const field = sections
          ?.find(s => s.id === sectionId)
          ?.fields?.find(f => f.id === fieldId);
        return customLabel || field?.label || '';
      }
      return customLabel || t(`sections.${sectionId}.fields.${fieldId}.label`) || '';
    };
    
    export const formatHeight = (value, selectedLanguage) => {
      if (!value) return '';
      const [feet, inches] = value.split('_');
      const cm = Math.round(((parseInt(feet) * 12 + parseInt(inches)) * 2.54));
      return selectedLanguage === 'hindi' 
        ? `${feet} फीट ${inches} इंच (${cm} सेमी)`
        : `${feet} Feet ${inches} Inches (${cm} cm)`;
    };
    
    export const formatDate = (dateString, selectedLanguage, monthTranslations) => {
      try {
        const date = new Date(dateString);
        const day = date.getDate();
        const monthInEnglish = date.toLocaleString('en-US', { month: 'long' });
        const year = date.getFullYear();
        const translatedMonth = monthTranslations[selectedLanguage]?.[monthInEnglish] || monthInEnglish;
        return `${day} ${translatedMonth}, ${year}`;
      } catch {
        return dateString;
      }
    };
    
    export const formatTime = (value, selectedLanguage) => {
      try {
        const [hours, minutes] = value.split(':');
        const hour = parseInt(hours, 10);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minutes} ${ampm}`;
      } catch {
        return value;
      }
    };
    
    export const formatPhone = (value) => {
      const cleaned = value.replace(/\D/g, '');
      if (cleaned.length === 10) {
        return `+91-${cleaned}`;
      }
      return value;
    };
    
    export const formatSiblings = (sectionData, selectedLanguage, t) => {
      if (!sectionData || !t || typeof t !== 'function') return ''; // Add check for t
      
      const {
        totalBrothers = '',
        totalSisters = '',
        brothersMarried = '0',
        sistersMarried = '0'
      } = sectionData;
    
      // Convert to numbers, use 0 if empty string
      const numTotalBrothers = totalBrothers ? parseInt(totalBrothers, 10) : 0;
      const numTotalSisters = totalSisters ? parseInt(totalSisters, 10) : 0;
      const numBrothersMarried = parseInt(brothersMarried, 10);
      const numSistersMarried = parseInt(sistersMarried, 10);
    
      let result = '';
    
      // Only calculate total if either value is provided
      if (totalBrothers || totalSisters) {
        const total = numTotalBrothers + numTotalSisters;
        if (total > 0) {
          result = `${total}, `;
        }
      }
    
      // Add married siblings info if any
      const marriedParts = [];
      if (numBrothersMarried > 0) {
        const brotherText = numBrothersMarried === 1
          ? t('sections.familyDetails.fields.siblings.brother')
          : t('sections.familyDetails.fields.siblings.brothers');
        marriedParts.push(`${numBrothersMarried} ${brotherText}`);
      }
    
      if (numSistersMarried > 0) {
        const sisterText = numSistersMarried === 1
          ? t('sections.familyDetails.fields.siblings.sister')
          : t('sections.familyDetails.fields.siblings.sisters');
        marriedParts.push(`${numSistersMarried} ${sisterText}`);
      }
    
      if (marriedParts.length > 0) {
        result += `${t('sections.familyDetails.fields.siblings.married')} (${
          marriedParts.join(` ${t('sections.familyDetails.fields.siblings.and')} `)
        })`;
      }
    
      return result;
    };
    
    export const formatValue = (value, type, fieldId, sectionId, { selectedLanguage, monthTranslations, t, getFieldLabel }) => {
      if (!value) return '';
      
      switch (type) {
        case 'select':
          if (fieldId === 'height') {
            return formatHeight(value, selectedLanguage);
          }
          return t(`sections.${sectionId}.fields.${fieldId}.options.${value}`) || value;
    
        case 'date':
          return formatDate(value, selectedLanguage, monthTranslations);
    
        case 'time':
          return formatTime(value, selectedLanguage);
    
        case 'tel':
          return formatPhone(value);
    
        case 'image':
          // Return only the value for image type, not an object
          if (typeof value === 'object' && 'value' in value) {
            return value.value;
          }
          return value;
    
        default:
          return value;
      }
    };
    
    export const shouldShowField = (sectionId, fieldId) => {
      const excludedFields = {
        education: [],
        familyDetails: [
          'siblings',
          'brothersMarried',
          'brothersUnmarried',
          'sistersMarried',
          'sistersUnmarried',
          'fatherOrganization',
          'motherOrganization',
          'totalBrothers',
          'totalSisters'
        ]
      };
    
      return !(excludedFields[sectionId] && excludedFields[sectionId].includes(fieldId));
    };
    
    export const hasValue = (value) => value !== undefined && value !== null && value !== '';
    
    export const processFields = (section, formData, shouldShowField) => {
      if (!section || !formData) return [];
    
      const sectionData = formData[section.id] || {};
      const processedFields = new Set();
      
      let fields = section.fields.filter(field => {
        if (processedFields.has(field.id)) return false;
        processedFields.add(field.id);
        
        return hasValue(sectionData[field.id]) && 
               field.id !== 'profilePhoto' && 
               !(section.id === 'personalDetails' && field.id === 'name') &&
               shouldShowField(section.id, field.id);
      });
    
      if (section.id === 'familyDetails') {
        fields = [
          ...fields.filter(field => ['fatherName', 'fatherOccupation'].includes(field.id)),
          ...fields.filter(field => ['motherName', 'motherOccupation'].includes(field.id)),
          { id: 'siblings_info', isSiblingInfo: true },
          ...fields.filter(field => 
            !['fatherName', 'fatherOccupation', 'motherName', 'motherOccupation'].includes(field.id)
          )
        ];
      }
    
      return fields;
    };