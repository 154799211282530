import React from 'react';
import {
  getFieldLabel,
  formatHeight,
  formatDate,
  formatTime,
  formatPhone,
  formatSiblings,
  formatValue,
  hasValue,
  shouldShowField,
  processFields
} from '../hooks/templateUtils';
import { getTranslation, monthTranslations } from '../translations';

// Color palette
const colors = {
  primary: '#7C3AED', // Vibrant purple
  secondary: '#A78BFA', // Light purple
  dark: '#5B21B6', // Deep purple
  text: '#4B5563', // Gray for text
  background: '#F5F3FF', // Light purple background
  accent: '#DDD6FE' // Soft purple accent
};

const Template10 = ({ formData, selectedLanguage, sections, customLabels, isTemplateView = false }) => {
  const t = (key) => getTranslation(selectedLanguage, key);

  const overlayPattern = `
    radial-gradient(circle at 100% 0%, rgba(124, 58, 237, 0.08) 0%, transparent 25%),
    radial-gradient(circle at 0% 100%, rgba(167, 139, 250, 0.08) 0%, transparent 25%)
  `;

  const decorativeBorder = `
    linear-gradient(45deg, transparent 2%, ${colors.primary} 2%, ${colors.primary} 98%, transparent 98%) 1
  `;

  const getSectionTitle = (section) => {
    if (section.title && typeof section.title === 'string' && section.title !== t(`sections.${section.id}.title`)) {
      return section.title;
    }
    return t(`sections.${section.id}.title`);
  };

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-[500px] text-gray-300">
      <svg 
        className="w-20 h-20 text-gray-400" 
        fill="none" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        viewBox="0 0 24 24" 
      >
        <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
      <h3 className="mb-2 text-xl font-medium text-gray-300">No Preview Available</h3>
      <p className="text-center text-gray-400 max-w-sm">
        Start filling in your information to see<br />your biodata preview in real-time.
      </p>
    </div>
  );

  const isFormEmpty = () => {
    return Object.keys(formData).length === 0 || 
           Object.values(formData).every(section => 
             Object.keys(section).length === 0 || 
             Object.values(section).every(value => !hasValue(value))
           );
  };

  const renderFields = (section, fields) => {
    const sectionData = formData[section.id] || {};

    return fields.map(field => {
      if (section.id === 'personalDetails' && field.id === 'dateOfBirth') {
        return null;
      }


                            if (field.isSiblingInfo && section.id === 'familyDetails') {
                              return (
                                <div key="siblings_info" className="flex py-0">
                                  <div className="w-[180px] text-m" style={{ color: colors.primary }}>
                                    {t('sections.familyDetails.fields.siblings.label')}
                                  </div>
                                  <div className="w-[70%]" style={{ color: colors.text }}>
                                    <div className="flex">
                                      <span className="mr-2">:</span>
                                      <div className="flex-1 break-words">
                                        {formatSiblings(sectionData, selectedLanguage, t)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

      const value = sectionData[field.id];
      let formattedValue = formatValue(value, field.type, field.id, section.id, {
        selectedLanguage,
        monthTranslations,
        t,
        getFieldLabel
      });

      if (field.id === 'education' && sectionData.collegeName) {
        formattedValue = `${formattedValue} (${sectionData.collegeName})`;
      } else if (field.id === 'occupation' && sectionData.organizationName) {
        formattedValue = `${formattedValue} (${sectionData.organizationName})`;
      } else if (field.id === 'fatherOccupation' && sectionData.fatherOrganization) {
        formattedValue = `${formattedValue} (${sectionData.fatherOrganization})`;
      } else if (field.id === 'motherOccupation' && sectionData.motherOrganization) {
        formattedValue = `${formattedValue} (${sectionData.motherOrganization})`;
      }

      if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
        return null;
      }

      return (
        <div key={field.id} className="flex py-0">
          <div className="w-[180px] text-m" style={{ color: colors.primary }}>
            {getFieldLabel(section.id, field.id, customLabels, sections, t)}
          </div>
          <div className="flex-1 text-m" style={{ color: colors.text }}>
            <div className="flex">
              <span className="mr-2">:</span>
              <div className="flex-1 break-words">{formattedValue}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="relative rounded-lg shadow-lg p-2 flex flex-col min-h-[800px] w-full" 
      style={{
        background: `linear-gradient(to bottom right, ${colors.background}, #FFFFFF), ${overlayPattern}`,
        borderImage: decorativeBorder,
        borderWidth: '2px',
        borderStyle: 'solid'
      }}>
      
      {/* Background Decorative Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-20 -left-20 w-40 h-40 rounded-full"
             style={{
               background: `radial-gradient(circle, ${colors.accent}33 0%, transparent 70%)`
             }} />
        <div className="absolute -bottom-20 -right-20 w-40 h-40 rounded-full"
             style={{
               background: `radial-gradient(circle, ${colors.accent}33 0%, transparent 70%)`
             }} />
        <div className="absolute top-1/2 left-0 right-0 h-[1px]"
             style={{
               background: `linear-gradient(90deg, transparent, ${colors.accent}55, transparent)`
             }} />
      </div>

      {/* Border Decoration */}
      <div className="absolute inset-0">
        <div className="h-full w-full border-[3px] rounded-lg" 
             style={{ 
               borderImage: `linear-gradient(45deg, ${colors.dark}, ${colors.secondary}, ${colors.primary}, ${colors.secondary}) 1`,
               boxShadow: `inset 0 0 20px ${colors.primary}19`
             }} />
      </div>

      {/* Main Content */}
      <div className="relative w-[85%] max-w-3xl mx-auto styled-scrollbar px-4 flex flex-col flex-grow">
        {isFormEmpty() ? (
          <EmptyState />
        ) : (
          <>
            {/* Mantra with God Images */}
            <div className="flex items-center justify-center gap-4 mt-0 mb-0">
              <img 
                src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
                alt="God Image Left"
                className="w-10 h-10 object-contain" 
                style={{ filter: 'opacity(0.9) sepia(20%) hue-rotate(240deg)' }}
                onError={(e) => {
                  e.target.src = '/images/gods/ganesha1.png';
                  e.target.onerror = null;
                }}
              />
              <div className="text-xl font-medium px-4 mb-4 py-2 rounded-lg text-center"
                   style={{ 
                     color: colors.dark,
                     fontFamily: 'Cormorant Garamond',
                     background: `linear-gradient(to right, transparent, ${colors.accent}33, transparent)`
                   }}>
                {formData?.customization?.mantra || t('sections.customization.fields.mantra.placeholder')}
              </div>
              <img 
                src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
                alt="God Image Right"
                className="w-10 h-10 object-contain" 
                style={{ filter: 'opacity(0.9) sepia(20%) hue-rotate(240deg)' }}
                onError={(e) => {
                  e.target.src = '/images/gods/ganesha1.png';
                  e.target.onerror = null;
                }}
              />
            </div>

            {/* Profile Section */}
            <div className="flex flex-col items-center">
              <div className="flex items-center gap-4 mb-4">
                {formData?.photoUpload?.profilePhoto && (
                  <div className="relative w-24 h-24 rounded-full overflow-hidden flex-shrink-0">
                    <div className="absolute inset-0 rounded-full border-2" 
                         style={{ borderColor: colors.primary }} />
                    <img 
                      src={formData.photoUpload.profilePhoto}
                      alt="Profile"
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.style.display = 'none';
                      }}
                    />
                  </div>
                )}

                <div className="text-center">
                  <h1 className="text-xl font-medium relative mb-2" 
                      style={{ color: colors.primary, fontFamily: 'Cormorant Garamond' }}>
                    {formData?.customization?.biodataTitle || t('sections.customization.fields.biodataTitle.placeholder')}
                  </h1>

                  <h2 className="text-2xl font-bold" 
                      style={{ color: colors.dark, fontFamily: 'Cormorant Garamond' }}>
                    {formData?.personalDetails?.name}
                  </h2>
                  
                  {formData?.personalDetails?.dateOfBirth && (
                    <p className="text-m mt-1" style={{ color: colors.text }}>
                      {formatDate(formData.personalDetails.dateOfBirth, selectedLanguage, monthTranslations)}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Content Sections */}
            <div className="space-y-3">
              {sections.map((section) => {
                if (section.id === 'customization') return null;

                const fields = processFields(section, formData, shouldShowField);
                if (fields.length === 0) return null;

                return (
                  <div key={section.id} className="mb-0">
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full mr-2" 
                           style={{ background: colors.primary }} />
                      <h3 className="text-lg mb-4 font-medium" 
                          style={{ color: colors.dark, fontFamily: 'Cormorant Garamond' }}>
                        {getSectionTitle(section)}
                      </h3>
                      <div className="flex-grow h-[1px] ml-4" 
                           style={{ background: `linear-gradient(to right, ${colors.primary}, transparent)` }} />
                    </div>

                    <div className="space-y-1 pl-1">
                      {renderFields(section, fields)}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Footer */}
            <div className="mt-auto pt-8">
              <div className="flex items-center justify-center gap-4">
                <div className="h-[2px] w-16" 
                     style={{ background: `linear-gradient(to right, transparent, ${colors.primary})` }} />
                <div className="w-3 h-3 rounded-full" 
                     style={{ background: colors.secondary }} />
                <div className="h-[2px] w-16" 
                     style={{ background: `linear-gradient(to left, transparent, ${colors.primary})` }} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Template10.isPremium = false;
export default Template10;