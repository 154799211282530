// Template11.jsx
import React from 'react';
import {
  getFieldLabel,
  formatHeight,
  formatDate,
  formatTime,
  formatPhone,
  formatSiblings,
  formatValue,
  hasValue,
  shouldShowField,
  processFields
} from '../hooks/templateUtils';
import { getTranslation, monthTranslations } from '../translations';

const Template11 = ({ formData, selectedLanguage, sections, customLabels, isTemplateView = false }) => {
  const t = (key) => getTranslation(selectedLanguage, key);

  const getSectionTitle = (section) => {
    if (section.title && typeof section.title === 'string' && section.title !== t(`sections.${section.id}.title`)) {
      return section.title;
    }
    return t(`sections.${section.id}.title`);
  };

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-[500px] text-gray-300">
      <svg 
        className="w-20 h-20 text-gray-400" 
        fill="none" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        viewBox="0 0 24 24" 
      >
        <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
      <h3 className="mb-2 text-xl font-medium text-gray-300">No Preview Available</h3>
      <p className="text-center text-gray-400 max-w-sm">
        Start filling in your information to see<br />your biodata preview in real-time.
      </p>
    </div>
  );

  const isFormEmpty = () => {
    return Object.keys(formData).length === 0 || 
           Object.values(formData).every(section => 
             Object.keys(section).length === 0 || 
             Object.values(section).every(value => !hasValue(value))
           );
  };

  const TitleDecoration = ({ children }) => (
    <div className="relative py-2">
      <div className="flex items-center justify-center gap-6 mb-0">
        <div className="flex">
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: '#7C3AED' }} />
          <div className="w-16 h-[1px]" style={{ backgroundColor: '#A78BFA' }} />
        </div>
        <div className="w-4 h-4 transform rotate-45" style={{ backgroundColor: '#7C3AED' }} />
        <div className="flex">
          <div className="w-16 h-[1px]" style={{ backgroundColor: '#A78BFA' }} />
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: '#7C3AED' }} />
        </div>
      </div>
      
      {children}

      <div className="flex items-center justify-center gap-6 mt-2">
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: '#7C3AED' }} />
          <div className="w-16 h-[1px]" style={{ backgroundColor: '#A78BFA' }} />
        </div>
        <div className="w-4 h-4 transform rotate-45" style={{ backgroundColor: '#7C3AED' }} />
        <div className="flex items-center">
          <div className="w-16 h-[1px]" style={{ backgroundColor: '#A78BFA' }} />
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: '#7C3AED' }} />
        </div>
      </div>
    </div>
  );
  
  return (
    <div className="relative rounded-lg shadow-lg p-2 flex flex-col min-h-[800px]" 
         style={{
           backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.97), rgba(255, 255, 255, 0.97)), url('/images/templates/ganesha-background.png')`,
           backgroundPosition: '85% 85%',
           backgroundSize: '40%',
           backgroundRepeat: 'no-repeat'
         }}>
      <div className="absolute inset-0">
        <div className="h-full w-full border-2 rounded-lg" style={{ borderColor: '#7C3AED' }}>
          <div className="absolute inset-2 rounded-lg opacity-5" 
               style={{ background: 'linear-gradient(135deg, #7C3AED, #A78BFA)' }} />
          
          {/* Corner Designs */}
          <div className="absolute -top-2 -left-2">
            <div className="relative">
              <div className="absolute w-6 h-6" style={{ 
                backgroundColor: '#7C3AED',
                clipPath: 'polygon(0 0, 100% 0, 0 100%)',
                opacity: 0.2
              }} />
              <div className="absolute w-4 h-4 top-1 left-1" style={{ 
                backgroundColor: '#A78BFA',
                clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
                opacity: 0.3
              }} />
            </div>
          </div>

          <div className="absolute -top-2 -right-2">
            <div className="relative">
              <div className="absolute w-6 h-6" style={{ 
                backgroundColor: '#7C3AED',
                clipPath: 'polygon(100% 0, 0 0, 100% 100%)',
                opacity: 0.2
              }} />
              <div className="absolute w-4 h-4 top-1 right-1" style={{ 
                backgroundColor: '#A78BFA',
                clipPath: 'polygon(0 0, 0 100%, 100% 100%)',
                opacity: 0.3
              }} />
            </div>
          </div>

          <div className="absolute -bottom-2 -left-2">
            <div className="relative">
              <div className="absolute w-6 h-6" style={{ 
                backgroundColor: '#7C3AED',
                clipPath: 'polygon(0 100%, 100% 0, 0 0)',
                opacity: 0.2
              }} />
              <div className="absolute w-4 h-4 bottom-1 left-1" style={{ 
                backgroundColor: '#A78BFA',
                clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
                opacity: 0.3
              }} />
            </div>
          </div>

          <div className="absolute -bottom-2 -right-2">
            <div className="relative">
              <div className="absolute w-6 h-6" style={{ 
                backgroundColor: '#7C3AED',
                clipPath: 'polygon(100% 100%, 0 0, 100% 0)',
                opacity: 0.2
              }} />
              <div className="absolute w-4 h-4 bottom-1 right-1" style={{ 
                backgroundColor: '#A78BFA',
                clipPath: 'polygon(0 0, 0 100%, 100% 0)',
                opacity: 0.3
              }} />
            </div>
          </div>

          {/* Side Decorations */}
          <div className="absolute top-1/4 -left-1">
            <div className="w-2 h-2" style={{ 
              backgroundColor: '#A78BFA',
              clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)'
            }} />
          </div>
          <div className="absolute top-3/4 -left-1">
            <div className="w-2 h-2" style={{ 
              backgroundColor: '#A78BFA',
              clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)'
            }} />
          </div>
          <div className="absolute top-1/4 -right-1">
            <div className="w-2 h-2" style={{ 
              backgroundColor: '#A78BFA',
              clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)'
            }} />
          </div>
          <div className="absolute top-3/4 -right-1">
            <div className="w-2 h-2" style={{ 
              backgroundColor: '#A78BFA',
              clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)'
            }} />
          </div>
        </div>
      </div>

      <div className="relative w-[85%] max-w-3xl mx-auto styled-scrollbar px-4 flex flex-col flex-grow">
        {isFormEmpty() ? (
          <EmptyState />
        ) : (
          <>
            <div className="flex items-center justify-center gap-2 mt-2">
              <div className="flex items-center justify-center w-12">
                <div className="relative">
                  <img 
                    src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
                    alt="God Image Left"
                    className="w-8 h-8 object-cover rounded-full relative z-10" 
                    style={{ filter: 'opacity(0.8) sepia(20%) hue-rotate(270deg)' }}
                    onError={(e) => {
                      e.target.src = '/images/gods/ganesha1.png';
                      e.target.onerror = null;
                    }}
                  />
                </div>
              </div>
              
              <div className="flex items-center justify-center">
                <div className="font-medium text-m mb-4" 
                     style={{ 
                       fontFamily: 'Cormorant Garamond',
                       lineHeight: '40px', 
                       color: '#7C3AED',
                       textShadow: '1px 1px 2px rgba(124, 58, 237, 0.1)'
                     }}>
                  {formData?.customization?.mantra || t('sections.customization.fields.mantra.placeholder')}
                </div>
              </div>
              
              <div className="flex items-center justify-center w-12">
                <div className="relative">
                  <img 
                    src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
                    alt="God Image Right"
                    className="w-8 h-8 object-cover rounded-full relative z-10" 
                    style={{ filter: 'opacity(0.8) sepia(20%) hue-rotate(270deg)' }}
                    onError={(e) => {
                      e.target.src = '/images/gods/ganesha1.png';
                      e.target.onerror = null;
                    }}
                  />
                </div>
              </div>
            </div>

            <h1 className="text-xl font-bold text-center px-6 py-0 mb-1 relative" 
                style={{ color: '#7C3AED', fontFamily: 'Cormorant Garamond' }}>
              {formData?.customization?.biodataTitle || t('sections.customization.fields.biodataTitle.placeholder')}
            </h1>
              
            {formData?.personalDetails?.name && (
              <div className="mb-2">
                <h2 className="text-2xl font-semibold text-center mb-8" 
                    style={{ color: '#7C3AED', fontFamily: 'Cormorant Garamond' }}>
                  {formData.personalDetails.name}
                </h2>
                <div className="flex items-center justify-center mt-4 gap-2 px-12">
                  <div className="flex-grow h-[1px]" 
                       style={{ background: 'linear-gradient(to right, transparent, #A78BFA, #7C3AED, #A78BFA, transparent)' }} />
                  <div className="w-2 h-2" style={{ 
                    backgroundColor: '#7C3AED',
                    clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)'
                  }} />
                  <div className="w-16 h-[1px]" style={{ backgroundColor: '#A78BFA' }} />
                  <div className="w-3 h-3 transform rotate-45" style={{ backgroundColor: '#7C3AED' }} />
                  <div className="w-16 h-[1px]" style={{ backgroundColor: '#A78BFA' }} />
                  <div className="w-2 h-2" style={{ 
                    backgroundColor: '#7C3AED',
                    clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)'
                  }} />
                  <div className="flex-grow h-[1px]" 
                       style={{ background: 'linear-gradient(to right, transparent, #A78BFA, #7C3AED, #A78BFA, transparent)' }} />
                </div>
              </div>
            )}

            <div className="space-y-4">
              {sections.map((section) => {
                if (section.id === 'customization') return null;

                const fields = processFields(section, formData, shouldShowField);
                if (fields.length === 0) return null;

                return (
                  <div key={section.id} className="mt-2">
                    {section.id === 'personalDetails' ? (
                      <>
                        <div className="flex items-center mb-0">
                          <div className="flex-grow h-[1px]" 
                               style={{ background: 'linear-gradient(to right, transparent, #A78BFA, transparent)' }} />
                          <h3 className="px-3 mb-4 text-base font-medium" 
                              style={{ color: '#7C3AED', fontFamily: 'Cormorant Garamond' }}>
                            {getSectionTitle(section)}
                          </h3>
                          <div className="flex-grow h-[1px]" 
                               style={{ background: 'linear-gradient(to right, transparent, #A78BFA, transparent)' }} />
                        </div>
                        <div className="flex justify-between items-start gap-1">
                          <div className="flex-1 min-w-0">
                            <div className="space-y-1 text-left text-m">
                              {fields.map(field => {
                                const sectionData = formData[section.id] || {};
                                const value = sectionData[field.id];
                                let formattedValue = formatValue(value, field.type, field.id, section.id, {
                                  selectedLanguage,
                                  monthTranslations,
                                  t,
                                  getFieldLabel
                                });

                                if (field.id === 'education' && sectionData.collegeName) {
                                  formattedValue = `${formattedValue} (${sectionData.collegeName})`;
                                } else if (field.id === 'occupation' && sectionData.organizationName) {
                                  formattedValue = `${formattedValue} (${sectionData.organizationName})`;
                                }

                                if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
                                  return null;
                                }

                                return (
                                  <div key={field.id} className="flex py-0">
                                    <div className={`${formData?.photoUpload?.profilePhoto ? 'w-[200px]' : 'w-[180px]'}`} 
                                         style={{ color: '#9F7AEA' }}>
                                      {getFieldLabel(section.id, field.id, customLabels, sections, t)}
                                    </div>
                                    <div className="w-[70%]" style={{ color: '#7C3AED' }}>
                                      <div className="flex">
                                        <span className="mr-2">:</span>
                                        <div className="flex-1 break-words">{formattedValue}</div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {formData?.photoUpload?.profilePhoto && (
                            <div className="w-36 h-36 flex-shrink-0 rounded-full overflow-hidden" 
                                 style={{ border: '2px solid #A78BFA' }}>
                              <img 
                                src={formData.photoUpload.profilePhoto}
                                alt="Profile"
                                className="w-full h-full object-cover"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.style.display = 'none';
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center">
                          <div className="flex-grow h-[1px]" 
                               style={{ background: 'linear-gradient(to right, transparent, #A78BFA, transparent)' }} />
                          <h3 className="px-3 text-base font-medium mb-4" 
                              style={{ color: '#7C3AED', fontFamily: 'Cormorant Garamond' }}>
                            {getSectionTitle(section)}
                          </h3>
                          <div className="flex-grow h-[1px]" 
                               style={{ background: 'linear-gradient(to right, transparent, #A78BFA, transparent)' }} />
                        </div>
                        <div className="space-y-1 text-left text-m">
                          {fields.map(field => {
                            const sectionData = formData[section.id] || {};


                if (field.isSiblingInfo && section.id === 'familyDetails') {
                              return (
                                <div key="siblings_info" className="flex py-0">
                                  <div className="w-[180px] text-m" style={{ color: '#9F7AEA' }}>
                                    {t('sections.familyDetails.fields.siblings.label')}
                                  </div>
                                  <div className="w-[70%]" style={{ color: '#7C3AED' }}>
                                    <div className="flex">
                                      <span className="mr-2">:</span>
                                      <div className="flex-1 break-words">
                                        {formatSiblings(sectionData, selectedLanguage, t)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }


                            const value = sectionData[field.id];
                            let formattedValue = formatValue(value, field.type, field.id, section.id, {
                              selectedLanguage,
                              monthTranslations,
                              t,
                              getFieldLabel
                            });
                          
                            if (field.id === 'education' && sectionData.collegeName) {
                              formattedValue = `${formattedValue} (${sectionData.collegeName})`;
                            } else if (field.id === 'occupation' && sectionData.organizationName) {
                              formattedValue = `${formattedValue} (${sectionData.organizationName})`;
                            } else if (field.id === 'fatherOccupation' && sectionData.fatherOrganization) {
                              formattedValue = `${formattedValue} (${sectionData.fatherOrganization})`;
                            } else if (field.id === 'motherOccupation' && sectionData.motherOrganization) {
                              formattedValue = `${formattedValue} (${sectionData.motherOrganization})`;
                            }
                          
                            if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
                              return null;
                            }
                          
                            return (
                              <div key={field.id} className="flex py-0">
                                <div className="w-[180px]" style={{ color: '#9F7AEA' }}>
                                  {getFieldLabel(section.id, field.id, customLabels, sections, t)}
                                </div>
                                <div className="w-[70%]" style={{ color: '#7C3AED' }}>
                                  <div className="flex">
                                    <span className="mr-2">:</span>
                                    <div className="flex-1 break-words">{formattedValue}</div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="mt-8 text-center">
              <div className="flex items-center justify-center w-full my-1 opacity-80">
                <div className="flex-grow h-[1px] max-w-[100px]" 
                     style={{ background: 'linear-gradient(to right, transparent, #A78BFA, transparent)', marginTop: '16px' }} />
                <div className="mx-4 mt-4" style={{ 
                  width: '12px',
                  height: '12px',
                  backgroundColor: '#7C3AED',
                  clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)'
                }} />
                <div className="flex-grow h-[1px] max-w-[100px]" 
                     style={{ background: 'linear-gradient(to right, transparent, #A78BFA, transparent)', marginTop: '16px' }} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Template11.isPremium = true;
export default Template11;