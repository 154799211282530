// Template13.jsx
import React from 'react';
import {
  getFieldLabel,
  formatHeight,
  formatDate,
  formatTime,
  formatPhone,
  formatSiblings,
  formatValue,
  hasValue,
  shouldShowField,
  processFields
} from '../hooks/templateUtils';
import { getTranslation, monthTranslations } from '../translations';

const Template13 = ({ formData, selectedLanguage, sections, customLabels, isTemplateView = false }) => {
  const t = (key) => getTranslation(selectedLanguage, key);

  const getSectionTitle = (section) => {
    if (section.title && typeof section.title === 'string' && section.title !== t(`sections.${section.id}.title`)) {
      return section.title;
    }
    return t(`sections.${section.id}.title`);
  };

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-[500px] text-gray-300">
      <svg 
        className="w-20 h-20 mb-4 text-gray-400" 
        fill="none" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        viewBox="0 0 24 24" 
      >
        <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
      <h3 className="mb-2 text-xl font-medium text-gray-300">No Preview Available</h3>
      <p className="text-center text-gray-400 max-w-sm">
        Start filling in your information to see<br />your biodata preview in real-time.
      </p>
    </div>
  );

  const isFormEmpty = () => {
    return Object.keys(formData).length === 0 || 
           Object.values(formData).every(section => 
             Object.keys(section).length === 0 || 
             Object.values(section).every(value => !hasValue(value))
           );
  };

  return (
    <div 
      className="relative w-[600px] h-[919px] mx-auto bg-gradient-to-b from-amber-50/40 via-white to-amber-50/40"
      style={{
        backgroundImage: `
          linear-gradient(to bottom, rgba(251, 243, 232, 0.4), white, rgba(251, 243, 232, 0.4)),
          url("data:image/svg+xml,%3Csvg width='600' height='919' viewBox='0 0 600 919' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 5H580' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M20 914H580' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M5 20V899' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M595 20V899' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M5 5L20 20' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M580 20L595 5' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M5 914L20 899' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M580 899L595 914' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M5 5C5 5 40 5 60 5C80 5 80 20 100 20C120 20 120 5 140 5C160 5 180 5 180 5' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M595 5C595 5 560 5 540 5C520 5 520 20 500 20C480 20 480 5 460 5C440 5 420 5 420 5' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M5 914C5 914 40 914 60 914C80 914 80 899 100 899C120 899 120 914 140 914C160 914 180 914 180 914' stroke='%23C8A36C' stroke-width='2'/%3E%3Cpath d='M595 914C595 914 560 914 540 914C520 914 520 899 500 899C480 899 480 914 460 914C440 914 420 914 420 914' stroke='%23C8A36C' stroke-width='2'/%3E%3C/svg%3E")
        `,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '100% 100%'
      }}
    >
      {/* Main Content */}
      <div className="relative w-[90%] mx-auto h-full pt-8 pb-8 px-8">
        {isFormEmpty() ? (
          <EmptyState />
        ) : (
          <>
            {/* Single God Image at Top */}
            <div className="flex justify-center mb-0">
              <div className="w-16 h-16">
                <img 
                  src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
                  alt="God Image"
                  className="w-full h-full object-cover rounded-full" 
                  onError={(e) => {
                    e.target.src = '/images/gods/ganesha1.png';
                    e.target.onerror = null;
                  }}
                />
              </div>
            </div>
  
            {/* Mantra */}
            <div className="text-amber-800 font-medium text-xl text-center mb-2" style={{ fontFamily: 'Cormorant Garamond', lineHeight: '40px' }}>
              {formData?.customization?.mantra || t('sections.customization.fields.mantra.placeholder')}
            </div>

            {/* Biodata Title */}
            <h1 className="text-xl font-bold text-amber-900 mb-0 text-center" style={{ fontFamily: 'Playfair Display' }}>
              {formData?.customization?.biodataTitle || t('sections.customization.fields.biodataTitle.placeholder')}
            </h1>
              
            {/* Profile Name */}
            {formData?.personalDetails?.name && (
              <h2 className="text-3xl font-bold text-amber-900 mb-6 text-center" style={{ fontFamily: 'Playfair Display' }}>
                {formData.personalDetails.name}
              </h2>
            )}

            {/* Content sections */}
            <div className="space-y-4">
              {sections.map((section) => {
                if (section.id === 'customization') return null;

                const fields = processFields(section, formData, shouldShowField);
                if (fields.length === 0) return null;

                return (
                  <div key={section.id} className="mb-3">
                    {section.id === 'personalDetails' ? (
                      <>
                        <h3 className="text-amber-800 text-lg font-bold text-left mb-2" style={{ fontFamily: 'Cormorant Garamond' }}>
                          ❧ {getSectionTitle(section)} ❧
                        </h3>
                        <div className="flex justify-between items-start gap-1">
                          <div className="flex-1 min-w-0">
                            <div className="space-y-[3px] text-left text-m" style={{ fontFamily: 'Lora' }}>
                              {fields.map(field => {
                                const sectionData = formData[section.id] || {};
                                const value = sectionData[field.id];
                                let formattedValue = formatValue(value, field.type, field.id, section.id, {
                                  selectedLanguage,
                                  monthTranslations,
                                  t,
                                  getFieldLabel
                                });

                                if (field.id === 'education' && sectionData.collegeName) {
                                  formattedValue = `${formattedValue} (${sectionData.collegeName})`;
                                } else if (field.id === 'occupation' && sectionData.organizationName) {
                                  formattedValue = `${formattedValue} (${sectionData.organizationName})`;
                                }

                                if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
                                  return null;
                                }

                                return (
                                  <div key={field.id} className="flex py-0">
                                    <div className={`${formData?.photoUpload?.profilePhoto ? 'w-[180px]' : 'w-[200px]'} text-amber-800`}>
                                      {getFieldLabel(section.id, field.id, customLabels, sections, t)}
                                    </div>
                                    <div className="flex-1 text-amber-950">
                                      <span className="mr-2">:</span>
                                      {formattedValue}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {formData?.photoUpload?.profilePhoto && (
  <div className="relative w-36 h-48 flex-shrink-0">
    <div className="absolute inset-0 border-2 border-amber-800/30 rounded-lg" />
    <div className="absolute inset-1 border border-amber-700/20 rounded-lg" />
    <img 
      src={formData.photoUpload.profilePhoto}
      alt="Profile"
      className="w-full h-full object-cover rounded-lg border-2 border-amber-800/30 p-0.5"
      onError={(e) => {
        e.target.onerror = null;
        e.target.style.display = 'none';
      }}
    />
  </div>
)}
                        </div>
                      </>
                    ) : (
                      <>
                        <h3 className="text-amber-800 text-lg font-bold text-left mb-2" style={{ fontFamily: 'Cormorant Garamond' }}>
                          ❧ {getSectionTitle(section)} ❧
                        </h3>
                        <div className="space-y-[3px] text-left text-m" style={{ fontFamily: 'Lora' }}>
                          {fields.map(field => {
                            const sectionData = formData[section.id] || {};
                            if (field.isSiblingInfo && section.id === 'familyDetails') {
                              return (
                                <div key="siblings_info" className="flex py-0">
                                  <div className={`${formData?.photoUpload?.profilePhoto ? 'w-[180px]' : 'w-[200px]'} text-amber-800`}>
                                    {t('sections.familyDetails.fields.siblings.label')}
                                  </div>
                                  <div className="flex-1 text-amber-950">
                                    <span className="mr-2">:</span>
                                    {formatSiblings(sectionData, selectedLanguage, t)}
                                  </div>
                                </div>
                              );
                            }

                            const value = sectionData[field.id];
                            let formattedValue = formatValue(value, field.type, field.id, section.id, {
                              selectedLanguage,
                              monthTranslations,
                              t,
                              getFieldLabel
                            });

                            if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
                              return null;
                            }

                            return (
                              <div key={field.id} className="flex py-0">
                                <div className={`${formData?.photoUpload?.profilePhoto ? 'w-[180px]' : 'w-[200px]'} text-amber-800`}>
                                  {getFieldLabel(section.id, field.id, customLabels, sections, t)}
                                </div>
                                <div className="flex-1 text-amber-950">
                                  <span className="mr-2">:</span>
                                  {formattedValue}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            {/* Footer */}
            <div className="mt-8 mb-1 text-center">
              <div className="flex items-center justify-center">
                <div className="h-px w-16 bg-amber-800/30"></div>
                <span className="mx-4 text-amber-800">❦</span>
                <div className="h-px w-16 bg-amber-800/30"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Template13.isPremium = true;
export default Template13;