// Template12.jsx
import React from 'react';
import {
  getFieldLabel,
  formatHeight,
  formatDate,
  formatTime,
  formatPhone,
  formatSiblings,
  formatValue,
  hasValue,
  shouldShowField,
  processFields
} from '../hooks/templateUtils';
import { getTranslation, monthTranslations } from '../translations';

const Template12 = ({ formData, selectedLanguage, sections, customLabels, isTemplateView = false }) => {
  const t = (key) => getTranslation(selectedLanguage, key);

  const getSectionTitle = (section) => {
    if (section.title && typeof section.title === 'string' && section.title !== t(`sections.${section.id}.title`)) {
      return section.title;
    }
    return t(`sections.${section.id}.title`);
  };

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-[500px] text-gray-300" style={{ fontFamily: 'Lora' }}>
      <svg 
        className="w-20 h-20 mb-4 text-gray-400" 
        fill="none" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        viewBox="0 0 24 24" 
      >
        <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
      <h3 className="mb-2 text-xl font-medium text-gray-300">No Preview Available</h3>
      <p className="text-center text-gray-400 max-w-sm">
        Start filling in your information to see<br />your biodata preview in real-time.
      </p>
    </div>
  );

  const isFormEmpty = () => {
    return Object.keys(formData).length === 0 || 
           Object.values(formData).every(section => 
             Object.keys(section).length === 0 || 
             Object.values(section).every(value => !hasValue(value))
           );
  };

  return (
    <div className="relative bg-gradient-to-br from-purple-900 via-purple-800 to-purple-900 rounded-lg shadow-lg p-2 flex flex-col min-h-[800px]">
      {/* Decorative Border */}
      <div className="absolute inset-0">
        <div className="h-full w-full border-2 border-purple-300/30 rounded-lg">
          {/* Inner border */}
          <div className="absolute inset-2 border border-purple-200/20 rounded-lg" />
          
          {/* Corner Decorations */}
          {['-top-3 -left-3', '-top-3 -right-3', '-bottom-3 -left-3', '-bottom-3 -right-3'].map((position, index) => (
            <div key={index} className={`absolute ${position} w-12 h-12`}>
              <div className="absolute inset-0 border-2 border-purple-300/30 rounded-lg transform rotate-45" />
              <div className="absolute inset-2 border border-purple-200/20 rounded-lg transform rotate-45" />
              <div className="absolute inset-0 w-2 h-2 bg-purple-300/40 rounded-full m-auto" />
            </div>
          ))}
  
          {/* Edge Center Decorations */}
          {['top', 'right', 'bottom', 'left'].map((edge, index) => (
            <div 
              key={edge}
              className={`absolute ${
                edge === 'left' || edge === 'right' 
                  ? `${edge}-0 top-1/2 transform -translate-y-1/2 h-32 w-1` 
                  : `${edge}-0 left-1/2 transform -translate-x-1/2 w-32 h-1`
              }`}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-purple-300/30 to-transparent" />
              <div className={`absolute ${
                edge === 'left' || edge === 'right'
                  ? 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3'
                  : 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3'
              } bg-purple-300/30 rounded-full`} />
            </div>
          ))}
        </div>
      </div>
  
      {/* Main Content */}
      <div className="relative w-[85%] max-w-3xl mx-auto styled-scrollbar px-4 flex flex-col flex-grow">
        {isFormEmpty() ? (
          <EmptyState />
        ) : (
          <>
            {/* Single God Image at Top */}
            <div className="flex justify-center mt-4">
              <div className="w-16 h-16">
                <img 
                  src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
                  alt="God Image"
                  className="w-full h-full object-cover rounded-full" 
                  onError={(e) => {
                    e.target.src = '/images/gods/ganesha1.png';
                    e.target.onerror = null;
                  }}
                />
              </div>
            </div>
  
            {/* Mantra */}
            <div className="text-purple-100 font-medium text-xl text-center mb-2" style={{ fontFamily: 'Birthstone', lineHeight: '40px' }}>
              {formData?.customization?.mantra || t('sections.customization.fields.mantra.placeholder')}
            </div>

            {/* Biodata Title */}
            <h1 className="text-xl font-semibold text-purple-200 mb-0 text-center" style={{ fontFamily: 'Playfair Display' }}>
              {formData?.customization?.biodataTitle || t('sections.customization.fields.biodataTitle.placeholder')}
            </h1>
              
            {/* Profile Name */}
            {formData?.personalDetails?.name && (
              <h2 className="text-3xl font-bold text-purple-50 mb-6 text-center" style={{ fontFamily: 'Cormorant Garamond' }}>
                {formData.personalDetails.name}
              </h2>
            )}

            {/* Rest of the content sections */}
            <div className="space-y-4" style={{ fontFamily: 'Lora' }}>
              {sections.map((section) => {
                if (section.id === 'customization') return null;

                const fields = processFields(section, formData, shouldShowField);
                if (fields.length === 0) return null;

                return (
                  <div key={section.id} className="mb-3">
                    {section.id === 'personalDetails' ? (
                      <>
                        <h3 className="text-purple-200 text-lg font-medium text-left mb-2" style={{ fontFamily: 'Playfair Display' }}>
                          ※ {getSectionTitle(section)} ※
                        </h3>
                        <div className="flex justify-between items-start gap-1">
                          <div className="flex-1 min-w-0">
                            <div className="space-y-[2px] text-left text-m">
                              {fields.map(field => {
                                const sectionData = formData[section.id] || {};
                                const value = sectionData[field.id];
                                let formattedValue = formatValue(value, field.type, field.id, section.id, {
                                  selectedLanguage,
                                  monthTranslations,
                                  t,
                                  getFieldLabel
                                });

                                if (field.id === 'education' && sectionData.collegeName) {
                                  formattedValue = `${formattedValue} (${sectionData.collegeName})`;
                                } else if (field.id === 'occupation' && sectionData.organizationName) {
                                  formattedValue = `${formattedValue} (${sectionData.organizationName})`;
                                }

                                if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
                                  return null;
                                }

                                return (
                                  <div key={field.id} className="flex py-0"> 
                                    <div className={`${formData?.photoUpload?.profilePhoto ? 'w-[200px]' : 'w-[180px]'} text-purple-200/90`}>
                                      {getFieldLabel(section.id, field.id, customLabels, sections, t)}
                                    </div>
                                    <div className="w-[70%] text-purple-50">
                                      <div className="flex">
                                        <span className="mr-2">:</span>
                                        <div className="flex-1 break-words">{formattedValue}</div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {formData?.photoUpload?.profilePhoto && (
                            <div className="relative w-36 h-auto flex-shrink-0">
                              <div className="absolute inset-0 border-2 border-purple-300/30 rounded-full" />
                              <div className="absolute inset-1 border border-purple-200/20 rounded-full" />
                              <img 
                                src={formData.photoUpload.profilePhoto}
                                alt="Profile"
                                className="w-full h-full object-cover rounded-full border-2 border-purple-300/30 p-0.5"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.style.display = 'none';
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <h3 className="text-purple-200 text-lg font-medium text-left mb-2" style={{ fontFamily: 'Playfair Display' }}>
                          ※ {getSectionTitle(section)} ※
                        </h3>
                        <div className="space-y-[2px] text-left text-m">
                          {fields.map(field => {
                            const sectionData = formData[section.id] || {};
                            if (field.isSiblingInfo && section.id === 'familyDetails') {
                              return (
                                <div key="siblings_info" className="flex py-0">
                                  <div className="w-[180px] text-purple-200/90">
                                    {t('sections.familyDetails.fields.siblings.label')}
                                  </div>
                                  <div className="w-[70%] text-purple-50">
                                    <div className="flex">
                                      <span className="mr-2">:</span>
                                      <div className="flex-1 break-words">
                                        {formatSiblings(sectionData, selectedLanguage, t)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

                            const value = sectionData[field.id];
                            let formattedValue = formatValue(value, field.type, field.id, section.id, {
                              selectedLanguage,
                              monthTranslations,
                              t,
                              getFieldLabel
                            });

                            if (field.id === 'fatherOccupation' && sectionData.fatherOrganization) {
                              formattedValue = `${formattedValue} (${sectionData.fatherOrganization})`;
                            } else if (field.id === 'motherOccupation' && sectionData.motherOrganization) {
                              formattedValue = `${formattedValue} (${sectionData.motherOrganization})`;
                            }

                            if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
                              return null;
                            }

                            return (
                              <div key={field.id} className="flex py-0">
                                <div className="w-[180px] text-purple-200/90">
                                  {getFieldLabel(section.id, field.id, customLabels, sections, t)}
                                </div>
                                <div className="w-[70%] text-purple-50">
                                  <div className="flex">
                                    <span className="mr-2">:</span>
                                    <div className="flex-1 break-words">{formattedValue}</div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            {/* Footer */}
            <div className="mt-8 mb-1 text-center" style={{ fontFamily: 'Cormorant Garamond' }}>
              <div className="flex items-center justify-center w-full my-1 opacity-80">
                <div className="flex-grow h-px max-w-[100px] bg-gradient-to-r from-transparent via-purple-300/30 to-transparent mt-4" />
                <div className="mx-4 text-purple-200 relative">
                  <span className="text-lg">✤</span>
                  <div className="absolute -top-1 -left-1 w-3 h-3 border border-purple-300/30 rounded-full" />
                  <div className="absolute -bottom-1 -right-1 w-3 h-3 border border-purple-300/30 rounded-full" />
                </div>
                <div className="flex-grow h-px max-w-[100px] bg-gradient-to-r from-transparent via-purple-300/30 to-transparent mt-4" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Template12.isPremium = true;
export default Template12;