// BiodataPreview.jsx
import React, { useState, useEffect } from 'react';
import { getTranslation, monthTranslations } from './translations';

const BiodataPreview = ({ formData, selectedLanguage, sections, customLabels,   isPreview = false,  isTemplateView = false, selectedTemplate = 'default'}) => {
  const t = (key) => getTranslation(selectedLanguage, key);

  const getFieldLabel = (sectionId, fieldId) => {
    if (!sectionId || !fieldId) return '';
    
    const customLabel = customLabels?.[`${sectionId}.${fieldId}`];
    if (fieldId.startsWith('custom_')) {
      const field = sections
        ?.find(s => s.id === sectionId)
        ?.fields?.find(f => f.id === fieldId);
      return customLabel || field?.label || '';
    }
    return customLabel || t(`sections.${sectionId}.fields.${fieldId}.label`) || '';
  };
 
  useEffect(() => {
    if (!isFormEmpty()) {
      const biodataStorage = {
        formData,
        selectedLanguage,
        sections,
        customLabels,
        lastUpdated: new Date().toISOString()
      };
      localStorage.setItem('biodataStorage', JSON.stringify(biodataStorage));
    }
  }, [formData, selectedLanguage, sections, customLabels]);

  

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-[500px] text-gray-500">
    
      <svg 
        className="mx-auto h-24 w-24 text-gray-400" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V7a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
        />
      </svg>
    
      <h3 className="mb-2 text-xl font-medium text-gray-600">{t('emptyPreview.title')}</h3>
      <p className="text-center text-gray-500 max-w-sm">
      {t('emptyPreview.description')}
      </p>
    </div>
  );

  const formatHeight = (value) => {
    if (!value) return '';
    const [feet, inches] = value.split('_');
    const cm = Math.round(((parseInt(feet) * 12 + parseInt(inches)) * 2.54));
    return selectedLanguage === 'hindi' 
            ? `${feet} फीट ${inches} इंच (${cm} सेमी)`
            : `${feet} Feet ${inches} Inches (${cm} cm)`;
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      const day = date.getDate();
      const monthInEnglish = date.toLocaleString('en-US', { month: 'long' });
      const year = date.getFullYear();
      
      const translatedMonth = monthTranslations[selectedLanguage]?.[monthInEnglish] || monthInEnglish;
      
      return `${day} ${translatedMonth}, ${year}`;
    } catch {
      return dateString;
    }
  };

  const formatSiblings = (sectionData) => {
    const {
      totalBrothers = '',
      totalSisters = '', 
      brothersMarried = '0',
      sistersMarried = '0'
    } = sectionData;
  
    // Convert to numbers, use 0 if empty string
    const numTotalBrothers = totalBrothers ? parseInt(totalBrothers, 10) : 0;
    const numTotalSisters = totalSisters ? parseInt(totalSisters, 10) : 0;
    const numBrothersMarried = parseInt(brothersMarried, 10);
    const numSistersMarried = parseInt(sistersMarried, 10);
  
    let result = '';
  
    // Only calculate total if both values are provided
    if (totalBrothers || totalSisters) {
      const total = numTotalBrothers + numTotalSisters;
      if (total > 0) {
        result = `${total}, `;
      }
    }
  
    // Add married siblings info if any
    const marriedParts = [];
    if (numBrothersMarried > 0) {
      const brotherText = numBrothersMarried === 1
        ? t('sections.familyDetails.fields.siblings.brother')
        : t('sections.familyDetails.fields.siblings.brothers');
      marriedParts.push(`${numBrothersMarried} ${brotherText}`);
    }
  
    if (numSistersMarried > 0) {
      const sisterText = numSistersMarried === 1
        ? t('sections.familyDetails.fields.siblings.sister')
        : t('sections.familyDetails.fields.siblings.sisters');
      marriedParts.push(`${numSistersMarried} ${sisterText}`);
    }
  
    if (marriedParts.length > 0) {
      result += `${t('sections.familyDetails.fields.siblings.married')} (${
        marriedParts.join(` ${t('sections.familyDetails.fields.siblings.and')} `)
      })`;
    }
  
    return result;
  };
  const formatValue = (value, type, fieldId, sectionId) => {
    if (!value) return '';
    
    switch (type) {
      case 'select':
        if (fieldId === 'height') {
          return formatHeight(value);
        }
        return t(`sections.${sectionId}.fields.${fieldId}.options.${value}`) || value;

      case 'date':
        return formatDate(value);

      case 'time':
        try {
          const [hours, minutes] = value.split(':');
          const hour = parseInt(hours, 10);
          const ampm = hour >= 12 ? (selectedLanguage === 'hindi' ? 'PM' : 'PM') : (selectedLanguage === 'hindi' ? 'AM' : 'AM');  
          const hour12 = hour % 12 || 12;
          return `${hour12}:${minutes} ${ampm}`;
        } catch {
          return value;
        }

      case 'tel':
        const cleaned = value.replace(/\D/g, '');
        if (cleaned.length === 10) {
          return `+91-${cleaned}`;
        }
        return value;

      case 'image':
        if (typeof value === 'string' && value.startsWith('data:image')) {
          return (
            <img 
              src={value}
              alt={t(`sections.${sectionId}.fields.${fieldId}.label`)}
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = 'none';
              }}
            />
          );
        }
        return value;

      default:
        return value;
    }
  };

  const hasValue = (value) => value !== undefined && value !== null && value !== '';

  const isFormEmpty = () => {
    return Object.keys(formData).length === 0 || 
           Object.values(formData).every(section => 
             Object.keys(section).length === 0 || 
             Object.values(section).every(value => !hasValue(value))
           );
  };

  const shouldShowField = (sectionId, fieldId) => {
    const excludedFields = {
      education: ['organizationName'],
      familyDetails: [
        'siblings',
        'brothersMarried',
        'brothersUnmarried',
        'sistersMarried',
        'sistersUnmarried',
        'fatherOrganization',
        'motherOrganization',
        'totalBrothers',
        'totalSisters'
      ]
    };

    return !(excludedFields[sectionId] && excludedFields[sectionId].includes(fieldId));
  };


  const getSectionTitle = (section) => {
    // First check if section has a custom title
    if (section.title && typeof section.title === 'string' && section.title !== t(`sections.${section.id}.title`)) {
      return section.title;
    }
    // Fallback to translation
    return t(`sections.${section.id}.title`);
  };

  // Main render
  return (
    <div className={`relative  bg-white rounded-lg shadow-lg p-2 border-8 border-double border-red-200 flex flex-col min-h-[800px] ${
      isTemplateView ? '' : ''
    }`}>
      {isFormEmpty() ? (
        <EmptyState />
      ) : (
        <div className="relative w-[85%] mx-auto styled-scrollbar px-4 flex flex-col flex-grow">
  {/* Mantra with God Images */}
<div className="flex items-center justify-center gap-2 mb-0">
  {/* Right God Image - Add flex and center alignment */}
<div className="flex items-center justify-center">
  <img 
    src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
    alt="God Image Right"
    className="w-8 h-8 object-contain" 
    onError={(e) => {
      e.target.src = '/images/gods/ganesha1.png';
      e.target.onerror = null;
    }}
  />
</div>
  
  {/* Mantra Text - Add flex and center alignment */}
  <div className="flex items-center justify-center text-red-500 font-medium text-m mb-4">
  {formData?.customization?.mantra || t('sections.customization.fields.mantra.placeholder')}
</div>

{/* Right God Image - Add flex and center alignment */}
<div className="flex items-center justify-center">
  <img 
    src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
    alt="God Image Right"
    className="w-8 h-8 object-contain" 
    onError={(e) => {
      e.target.src = '/images/gods/ganesha1.png';
      e.target.onerror = null;
    }}
  />
</div>
</div>

          {/* Biodata Title */}
          <h1 className={`${isTemplateView ? 'text-2xl' : 'text-xl'} font-bold text-red-600 mt-0 text-center`}>
  {formData?.customization?.biodataTitle || t('sections.customization.fields.biodataTitle.placeholder')}
</h1>
            
          {/* Profile Name */}
          {formData?.personalDetails?.name && (
  <h2 className={`${isTemplateView ? 'text-3xl' : 'text-2xl'} font-semibold text-gray-800 mb-6 text-center`}>
    {formData.personalDetails.name}
  </h2>
)}

          {/* Content Sections */}
          <div className="space-y-4">
            {sections.map((section) => {
              if (section.id === 'customization') return null;

              const sectionData = formData[section.id] || {};
              const processedFields = new Set();
              
              let orderedFields = section.fields.filter(field => {
                if (processedFields.has(field.id)) return false;
                processedFields.add(field.id);
                
                return hasValue(sectionData[field.id]) && 
                       field.id !== 'profilePhoto' && 
                       !(section.id === 'personalDetails' && field.id === 'name') &&
                       shouldShowField(section.id, field.id);
              });

              if (orderedFields.length === 0) return null;

              if (section.id === 'familyDetails') {
                orderedFields = [
                  ...orderedFields.filter(field => ['fatherName', 'fatherOccupation'].includes(field.id)),
                  ...orderedFields.filter(field => ['motherName', 'motherOccupation'].includes(field.id)),
                  { id: 'siblings_info', isSiblingInfo: true },
                  ...orderedFields.filter(field => 
                    !['fatherName', 'fatherOccupation', 'motherName', 'motherOccupation'].includes(field.id)
                  )
                ];
              }

              return (
                <div key={section.id} className="mb-3">
       {section.id === 'personalDetails' ? (
            <>
              <h3 className={`text-red-500 ${isPreview ? 'text-lg' : 'text-base'} font-medium text-left mb-2`}>
                ※ {getSectionTitle(section)} ※
              </h3>
              <div className="flex justify-between items-start gap-1">
                <div className="flex-1 min-w-0">
                  <div className={`space-y-1 text-left ${isPreview ? 'text-base' : 'text-sm'}`}>
          {orderedFields.map(field => {
            const value = sectionData[field.id];
            let formattedValue = formatValue(value, field.type, field.id, section.id);

            if (field.id === 'education' && sectionData.collegeName) {
              formattedValue += ` (${sectionData.collegeName})`;
            } else if (field.id === 'occupation' && sectionData.organizationName) {
              formattedValue += ` (${sectionData.organizationName})`;
            }

            return (
              <div key={field.id} className="flex py-0">
                <div className={`${formData?.photoUpload?.profilePhoto ? 'w-[200px]' : 'w-[180px]'} text-gray-600`}>
                  {getFieldLabel(section.id, field.id)}
                </div>
                <div className="w-[70%] text-gray-800">
                  <div className="flex">
                    <span className="mr-2">:</span>
                    <div className="flex-1 break-words">{formattedValue}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {formData?.photoUpload?.profilePhoto && (
        <div className="w-36 h-auto flex-shrink-0 rounded-lg border border-rose-200 shadow-md overflow-hidden">
          {formatValue(
            formData.photoUpload.profilePhoto,
            'image',
            'profilePhoto',
            'photoUpload'
          )}
        </div>
      )}
    </div>
  </>
) : (
  <>
    <h3 className={`text-red-500 ${isPreview ? 'text-lg' : 'text-base'} font-medium text-left mb-2`}>
                ※ {getSectionTitle(section)} ※
              </h3>
              <div className={`space-y-1 text-left ${isPreview ? 'text-base' : 'text-sm'}`}>
      {orderedFields.map(field => {
                          if (field.isSiblingInfo && section.id === 'familyDetails' && 
                            (sectionData.brothersMarried || sectionData.brothersUnmarried || 
                              sectionData.sistersMarried || sectionData.sistersUnmarried)) {
                             return (
                               <div key="siblings_info" className="flex py-0">
                                <div className="w-[180px] text-gray-600">
                                    {t('sections.familyDetails.fields.siblings.label')}
                                </div>
                                 <div className="w-[70%] text-gray-800">
                                   <div className="flex">
                                     <span className="mr-2">:</span>
                                     <div className="flex-1 break-words">{formatSiblings(sectionData)}</div>
                                   </div>
                                 </div>
                               </div>
                             );
                           }

                          const value = sectionData[field.id];
                          let formattedValue = formatValue(value, field.type, field.id, section.id);

                          if (field.id === 'fatherOccupation' && sectionData.fatherOrganization) {
                            formattedValue += ` (${sectionData.fatherOrganization})`;
                          } else if (field.id === 'motherOccupation' && sectionData.motherOrganization) {
                            formattedValue += ` (${sectionData.motherOrganization})`;
                          } else if (field.id === 'education' && sectionData.collegeName) {
                            formattedValue += ` (${sectionData.collegeName})`;
                          } else if (field.id === 'occupation' && sectionData.organizationName) {
                            formattedValue += ` (${sectionData.organizationName})`;
                          }

                          if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
                            return null;
                          }

                          return (
                            <div key={field.id} className="flex py-0">
                              <div className="w-[180px] text-gray-600">
                                {getFieldLabel(section.id, field.id)}
                              </div>
                              <div className="w-[70%] text-gray-800">
                                <div className="flex">
                                  <span className="mr-2">:</span>
                                  <div className="flex-1 break-words">{formattedValue}</div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>

         {/* Footer */}
<div className="mt-auto mb-1 text-center">
  <div className="flex items-center justify-center w-full my-1 opacity-80">
    <div className="flex-grow h-[1px] max-w-[100px] bg-gradient-to-r from-transparent via-red-300 to-transparent mt-4"></div>
    <div className="mx-4 text-red-500">
      <span className="text-lg">✤</span>
    </div>
    <div className="flex-grow h-[1px] max-w-[100px] bg-gradient-to-r from-transparent via-red-300 to-transparent mt-4"></div>
  </div>
</div>
    </div>
      )}
    </div>
  );
};

export default BiodataPreview;