// src/components/Hero.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="bg-gradient-to-b from-rose-50 to-white pb-12 pt-2 md:pt-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <div className="flex flex-col md:grid md:grid-cols-2 gap-8 md:gap-12 items-center">
          {/* Image Section */}
          <div className="relative flex items-center justify-center order-1 md:order-2 w-full max-w-[400px] mx-auto">
            <div className="relative w-full bg-rose-50/80 rounded-2xl p-4 shadow-lg">
              {/* Single decorative border */}
              <div className="absolute inset-4 border-2 border-rose-200/70 rounded-3xl"></div>
              
              {/* Image container with fixed aspect ratio */}
              <div className="relative w-full pt-[100%]">
                {/* Loading placeholder with shimmer */}
                {!imageLoaded && (
                  <div className="absolute inset-0 bg-rose-50 overflow-hidden">
                    <div className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-rose-50 via-rose-100 to-rose-50"></div>
                  </div>
                )}
                <img 
                  src="/images/hero-couple.webp"
                  alt="Hero couple preview"
                  className={`absolute inset-0 w-full h-full object-contain transform hover:scale-105 transition-transform duration-500 ${
                    imageLoaded ? 'opacity-100' : 'opacity-0'
                  }`}
                  onLoad={() => setImageLoaded(true)}
                />
              </div>
              
              {/* Background decorative elements */}
              <div className="absolute -bottom-3 -right-3 w-20 h-20 bg-rose-500/20 rounded-full blur-sm"></div>
              <div className="absolute -top-3 -left-3 w-16 h-16 bg-rose-300/20 rounded-full blur-sm"></div>
            </div>
          </div>

          {/* Rest of the code remains the same */}
          <div className="space-y-6 order-2 md:order-1 text-center md:text-left mt-6 md:mt-0 md:pl-12">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900">
              Create Your Perfect
              <span className="text-rose-500 block mt-2">
                Biodata in Minutes
              </span>
            </h1>
            
            <p className="text-lg text-gray-600 leading-relaxed">
            Create your perfect matrimonial biodata with our customizable<br></br>and elegantly designed marriage biodata format in minutes.
            </p>

            <div className="flex flex-wrap gap-4 justify-center md:justify-start">
        <Link
          to="/create-biodata"
          className="group inline-flex items-center px-6 py-3 bg-rose-500 text-white font-medium rounded-full hover:bg-rose-600 transition-colors duration-200"
        >
          <span>Create My Biodata</span>
          <span className="relative ml-2 w-4 h-4">
            <span className="absolute inset-y-0 left-0 flex items-center">
              <svg 
                className="w-4 h-4 transition-transform duration-300 transform group-hover:translate-x-1 group-hover:opacity-0"
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <svg 
                className="absolute w-4 h-4 transition-all duration-300 transform -translate-x-3 group-hover:translate-x-0 opacity-0 group-hover:opacity-100"
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </span>
          </span>
        </Link>
        
        <Link
          to="/biodata-templates"
          className="inline-flex items-center px-6 py-3 border-2 border-rose-500 text-rose-500 font-medium rounded-full hover:bg-rose-50 transition-colors duration-200"
        >
          View Templates
        </Link>
      </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;