import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { templates } from './BioDataMaker/templates';

const A4_WIDTH_MM = 210;
const A4_HEIGHT_MM = 297;

class TemplateErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // console.error('Template render error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Error loading template</div>;
    }
    return this.props.children;
  }
}

const PaymentStatus = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('pending');
  const [error, setError] = useState(null);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const templateRef = React.useRef(null);
  
  const [biodataData, setBiodataData] = useState(() => {
    try {
      const storedData = localStorage.getItem('biodataData');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        // console.log('Initial biodata data:', parsedData);
        return parsedData;
      }
    } catch (error) {
      // console.error('Error loading initial biodata data:', error);
    }
    return {
      selectedTemplate: 'default',
      formData: {},
      selectedLanguage: 'en',
      sections: [],
      customLabels: {}
    };
  });

  const checkPaymentStatus = async (orderId) => {
    try {
      const baseUrl = process.env.NODE_ENV === 'production' 
        ? 'https://mbiodata-server.vercel.app'
        : 'http://localhost:8001';
  
      const response = await fetch(
        `${baseUrl}/api/payment-status/${orderId}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
    
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unable to verify payment status');
      }
    
      const data = await response.json();
      // console.log('Payment status check response:', data);
      
      if (!data?.data?.orderStatus) {
        throw new Error('Payment status information is incomplete');
      }
      
      return data.data.orderStatus === 'PAID';
    } catch (error) {
      // console.error('Payment status check failed:', error);
      throw new Error(
        'We are having trouble confirming your payment. ' +
        'If you have completed the payment, please wait a moment and refresh the page. ' +
        'If the issue persists, please contact our support team.'
      );
    }
  };

  const generateFiles = async () => {
    setIsGeneratingPdf(true);
    try {
      if (!templates[biodataData.selectedTemplate]) {
        throw new Error(`Template ${biodataData.selectedTemplate} not found`);
      }

      if (!biodataData.formData || Object.keys(biodataData.formData).length === 0) {
        throw new Error('No biodata data found');
      }

      // console.log('Starting file generation with template:', biodataData.selectedTemplate);

      await document.fonts.ready;
      
      const templateEl = templateRef.current.querySelector('.template-content');
      if (!templateEl) {
        throw new Error('Template element not found');
      }
  
      const tempContainer = document.createElement('div');
      tempContainer.style.cssText = `
        width: 210mm;
        height: 297mm;
        position: absolute;
        left: -9999px;
        top: -9999px;
        background: white;
      `;
      document.body.appendChild(tempContainer);
  
      const clone = templateEl.cloneNode(true);
      clone.style.cssText = `
        width: 210mm;
        height: 297mm;
        transform: none;
        position: relative;
        background: white;
        padding: 2mm;
        margin: 0;
      `;
      tempContainer.appendChild(clone);
  
      const textElements = clone.querySelectorAll('*');
      textElements.forEach(el => {
        if (el.nodeType === 1) {
          const computed = window.getComputedStyle(el);
          el.style.lineHeight = computed.lineHeight;
          el.style.transform = 'none';
          el.style.position = computed.position;
          el.style.margin = computed.margin;
          el.style.padding = computed.padding;
        }
      });
  
      void tempContainer.offsetHeight;
      await new Promise(resolve => setTimeout(resolve, 500));
  
      const canvas = await html2canvas(clone, {
        scale: 5,
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: '#ffffff',
        width: clone.offsetWidth,
        height: clone.offsetHeight,
        windowWidth: clone.offsetWidth,
        windowHeight: clone.offsetHeight,
        imageTimeout: 15000,
        removeContainer: true,
        renderOptions: {
          alpha: false,
          antialias: true
        },
        onclone: (clonedDoc) => {
          const styles = clonedDoc.createElement('style');
          styles.textContent = `
            * {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              text-rendering: optimizeLegibility;
              line-height: normal !important;
              print-color-adjust: exact;
              -webkit-print-color-adjust: exact;
            }
          `;
          clonedDoc.head.appendChild(styles);
        }
      });

      let fileName = 'biodata';
      const name = biodataData.formData?.personalDetails?.name || '';
      if (name && typeof name === 'string' && name.trim()) {
        const cleanName = name.trim()
          .toLowerCase()
          .replace(/[^a-z0-9\s]/g, '')
          .replace(/\s+/g, '_');
        fileName = `biodata_${cleanName}`;
      }

      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        compress: true,
        precision: 16,
        hotfixes: ['px_scaling'],
        putOnlyUsedFonts: true,
      });

      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      
      pdf.addImage(
        imgData,
        'JPEG',
        0,
        0,
        A4_WIDTH_MM,
        A4_HEIGHT_MM,
        undefined,
        'FAST',
        {
          quality: 1.0,
          compression: 'NONE'
        }
      );
      
      const pdfFileName = `${fileName}.pdf`;
      pdf.save(pdfFileName);

      await new Promise(resolve => setTimeout(resolve, 500));

      const pngFileName = `${fileName}.png`;
      const link = document.createElement('a');
      link.download = pngFileName;
      link.href = canvas.toDataURL('image/png', 1.0);
      link.click();

      document.body.removeChild(tempContainer);
      return true;

    } catch (error) {
      // console.error('Error generating files:', error);
      alert('Error generating files. Please try again.');
      throw error;
    } finally {
      setIsGeneratingPdf(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    const processPayment = async () => {
      try {
        const url = new URL(window.location.href);
        const orderId = url.searchParams.get('order_id');
        const paymentStatus = url.searchParams.get('status');
    
        if (!orderId) {
          throw new Error('No order ID found in URL');
        }
    
        if (paymentStatus === 'success') {
          setStatus('success');
        } else if (paymentStatus === 'failed') {
          setStatus('failed');
          throw new Error('Payment was not successful');
        }
    
        await new Promise(resolve => setTimeout(resolve, 2000));
        
        if (!mounted) return;
        
        const isSuccessful = await checkPaymentStatus(orderId);
        if (!mounted) return;
        
        setStatus(isSuccessful ? 'success' : 'failed');
    
      } catch (error) {
        if (!mounted) return;
        // console.error('Payment processing error:', error);
        setError(error.message);
        setStatus('failed');
      }
    };

    processPayment();

    return () => {
      mounted = false;
    };
  }, []);

  const handleBack = () => {
    navigate('/create-biodata', { replace: true });
  };

  return (
    <div className="flex items-center justify-center py-20 bg-rose-50">
      <div className="p-8 bg-white rounded-lg shadow-lg text-center max-w-md w-full mx-8">
        {status === 'pending' && (
          <>
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-rose-600 mx-auto mb-6" />
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Verifying Payment...
            </h2>
            <p className="text-gray-600">
              Please wait while we confirm your transaction...
            </p>
          </>
        )}
        
        {status === 'success' && (
          <>
            <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <svg className="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Payment Successful!
            </h2>
            <p className="text-gray-600 mb-6">
              Click the button below to download your biodata files.
            </p>

            <div className="hidden" ref={templateRef}>
              <div className="template-content" style={{
                width: '210mm',
                height: '297mm',
                margin: '0 auto',
                backgroundColor: 'white',
                position: 'relative',
                padding: '2mm'
              }}>
                <TemplateErrorBoundary>
                  {biodataData.selectedTemplate && templates[biodataData.selectedTemplate] && (
                    React.createElement(templates[biodataData.selectedTemplate], {
                      formData: biodataData.formData,
                      selectedLanguage: biodataData.selectedLanguage,
                      sections: biodataData.sections,
                      customLabels: biodataData.customLabels,
                      isPreview: true
                    })
                  )}
                </TemplateErrorBoundary>
              </div>
            </div>

            <button
              onClick={generateFiles}
              disabled={isGeneratingPdf}
              className={`w-full ${
                isGeneratingPdf ? 'bg-rose-400' : 'bg-rose-600 hover:bg-rose-700'
              } text-white font-medium py-3 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center gap-2 mb-4`}
            >
              <span>
                {isGeneratingPdf ? 'Generating Files...' : 'Download Biodata Files'}
              </span>
              {isGeneratingPdf ? (
                <svg className="w-4 h-4 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
                </svg>
              ) : (
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                </svg>
              )}
            </button>

            <button
              onClick={handleBack}
              className="w-full border border-gray-300 text-gray-700 font-medium py-3 px-4 rounded-lg hover:bg-gray-50 transition-colors duration-200"
            >
              Back to Biodata
            </button>
          </>
        )}
        
        {status === 'failed' && (
          <>
            <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <svg className="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              {error ? 'Error Processing Payment' : 'Payment Failed'}
            </h2>
            <p className="text-gray-600 mb-6">
              {error || 'Please try again or contact support if the issue persists.'}
            </p>

            <button
              onClick={handleBack}
              className="w-full border border-gray-300 text-gray-700 font-medium py-3 px-4 rounded-lg hover:bg-gray-50 transition-colors duration-200"
            >
              Back to Biodata
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentStatus;