// App.jsx
import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Hero from './components/Hero';
import HowItWorks from './components/HowItWorks';
import Templates from './components/Templates';
import BioDataMaker from './components/BioDataMaker/BioDataMaker';
import BiodataAppFeatures from './components/biodataAppFeatures';
import FAQs from './components/FAQs';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import PaymentStatus from './components/PaymentStatus';

const App = () => {
  const location = useLocation();

  // Function to get page title based on route
  const getPageTitle = (pathname) => {
    switch (pathname) {
      case '/':
        return 'Matrimonial Biodata | Create Marriage Biodata Online';
      case '/biodata-templates':
        return 'Marriage Biodata Templates | Matrimonial Biodata';
      case '/how-it-works':
        return 'How It Works | Matrimonial Biodata';
      case '/create-biodata':
        return 'Create Marriage Biodata | Matrimonial Biodata';
      case '/faqs':
        return 'Frequently Asked Questions | Matrimonial Biodata';
      case '/terms-conditions':
        return 'Terms and Conditions | Matrimonial Biodata';
      case '/privacy-policy':
        return 'Privacy Policy | Matrimonial Biodata';
      case '/refund-policy':
        return 'Refund Policy | Matrimonial Biodata';
      case '/contact-us':
        return 'Contact Us | Matrimonial Biodata';
      case '/about-us':
        return 'About Us | Matrimonial Biodata';
      case '/biodata/payment/status':
        return 'Payment Status | Matrimonial Biodata';
      default:
        return 'Matrimonial Biodata | Create Marriage Biodata Online';
    }
  };

  // Meta description based on route
  const getMetaDescription = (pathname) => {
    switch (pathname) {
      case '/':
        return 'Create your perfect matrimonial biodata with our customizable and elegantly designed marriage biodata format in minutes.';
      case '/biodata-templates':
        return 'Browse our collection of beautiful marriage biodata formats. Choose the perfect template for your matrimonial biodata and customize it easily.';
      case '/how-it-works':
        return 'Learn how to create your marriage biodata in simple steps. Fill details, choose template, preview and download your biodata instantly.';
      case '/create-biodata':
        return 'Create your marriage biodata now. Fill your details, add photo and download your professional biodata in PDF format.';
      case '/faqs':
        return 'Find answers to frequently asked questions about creating marriage biodata, templates, downloading and more.';
      case '/terms-conditions':
        return 'Read our terms and conditions for using the matrimonial biodata maker service. Understand your rights and obligations.';
      case '/privacy-policy':
        return 'Our privacy policy explains how we collect, use, and protect your personal information when you use our biodata maker service.';
      case '/refund-policy':
        return 'Learn about our refund policy and the conditions under which you can request a refund for our biodata maker service.';
      case '/contact-us':
        return 'Contact us for any queries about marriage biodata maker. We are here to help you create the perfect biodata.';
      case '/about-us':
        return 'Learn about Matrimonial Biodata - The easiest way to create professional marriage biodata online.';
      case '/biodata/payment/status':
        return 'Check your payment status for marriage biodata download. Track your transaction and download your biodata.';
      default:
        return 'Create professional marriage biodata online with Matrimonial Biodata Maker. Choose from beautiful templates and download your biodata in PDF format instantly.';
    }
  };

  useEffect(() => {
    // Add cache control headers for payment status page
    if (location.pathname === '/biodata/payment/status') {
      // Add no-cache meta tags
      let metaNoCache = document.querySelector('meta[http-equiv="Cache-Control"]');
      if (!metaNoCache) {
        metaNoCache = document.createElement('meta');
        metaNoCache.setAttribute('http-equiv', 'Cache-Control');
        document.head.appendChild(metaNoCache);
      }
      metaNoCache.setAttribute('content', 'no-cache, no-store, must-revalidate');

      // Add pragma no-cache
      let metaPragma = document.querySelector('meta[http-equiv="Pragma"]');
      if (!metaPragma) {
        metaPragma = document.createElement('meta');
        metaPragma.setAttribute('http-equiv', 'Pragma');
        document.head.appendChild(metaPragma);
      }
      metaPragma.setAttribute('content', 'no-cache');

      // Add expires
      let metaExpires = document.querySelector('meta[http-equiv="Expires"]');
      if (!metaExpires) {
        metaExpires = document.createElement('meta');
        metaExpires.setAttribute('http-equiv', 'Expires');
        document.head.appendChild(metaExpires);
      }
      metaExpires.setAttribute('content', '0');
    }

    // Update document title
    document.title = getPageTitle(location.pathname);

    // Update meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.setAttribute('name', 'description');
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute('content', getMetaDescription(location.pathname));

    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="flex flex-col min-h-screen bg-rose-50/30">
      <Header />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Templates />
              <BiodataAppFeatures />
              <HowItWorks />
            </>
          } />
          <Route path="/biodata-templates" element={<Templates />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/create-biodata" element={<BioDataMaker />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route 
            path="/biodata/payment/status" 
            element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <PaymentStatus key={window.location.search || Date.now()} />
              </React.Suspense>
            } 
          />
          {/* Redirect any unmatched routes to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;